import apis from '../apis.js';

const deleteSubCategory = async (name, id) => {
  if (window.confirm(`Are you sure you want to delete ${name}?`)) {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(
        apis.delete.subCategory + id,
        requestOptions
      );

      const data = await response.json();
      alert(JSON.stringify(data.body));
    } catch (error) {
      alert('Error:', error);
    }
  } else {
    console.log('Delete Class cancelled');
  }
};

export default deleteSubCategory;
