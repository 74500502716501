import apis from '../apis.js';

async function deleteObject(id) {
  if (window.confirm('Are you sure you want to delete this object?')) {
    console.log(id);

    const response = await fetch(apis.delete.object + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    alert(data.body);
  }
}

export default deleteObject;
