// useDebounce.js

import { useRef, useCallback } from 'react';

function useDebounce(func, wait) {
  const timeoutRef = useRef(null);

  const debouncedFunction = useCallback(
    (...args) => {
      const context = this;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    },
    [func, wait]
  );

  return debouncedFunction;
}

export default useDebounce;
