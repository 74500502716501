import apis from '../apis.js';

const getPathViews = async () => {
    try {
        const response = await fetch(apis.path.get);
        const data = await response.json();
        console.log('GET path views:', data);
        return data;
    } catch (error) {
        console.log("Couldn't GET path views:", error);
        return [];
    }
    };

export default getPathViews;