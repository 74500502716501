import React, { useState } from 'react';
import ReusableModal from '../../../components/Modal';
import useDebounce from '../../../components/useDebounce';

const AddCategory = ({
  isOpen,
  onClose,
  addCategory,
  getCategories,
  setAbstractClasses,
}) => {
  const [abstractClassName, setAbstractClassName] = useState('');
  const [abstractClassDescription, setAbstractClassDescription] = useState('');

  const debouncedSetAbstractClassName = useDebounce((value) => {
    setAbstractClassName(value);
  }, 10);

  const handleAddCategory = () => {
    addCategory(abstractClassName, abstractClassDescription)
      .then(() => getCategories().then((data) => setAbstractClasses(data)))
      .then(() => {
        setAbstractClassName('');
        setAbstractClassDescription('');
        onClose();
      });
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex-db-add-new-abstract-class">
        <h2>Add New Category</h2>
        <label>
          Category Name <br />
          <input
            type="text"
            value={abstractClassName}
            onChange={(e) => debouncedSetAbstractClassName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Description <br />
          <input
            type="text"
            value={abstractClassDescription}
            onChange={(e) => setAbstractClassDescription(e.target.value)}
          />
        </label>
        <button onClick={handleAddCategory}>Add Category</button>
      </div>
    </ReusableModal>
  );
};

export default AddCategory;
