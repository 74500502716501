import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { saveAs } from 'file-saver';
import './styles/jepx.css';

const Jepx = () => {
  //#region State Variables
  const socketRef = useRef(null);
  const { accounts } = useMsal();
  const tableRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('test');
  const [startDate, setStartDate] = useState(
    localStorage.getItem('startDate') || '2024-01-01 00:00:00'
  );
  const [testingDays, setTestingDays] = useState(
    Number(localStorage.getItem('testingMonths')) || 18
  );
  const [trainingStartDate, setTrainingStartDate] = useState(
    '2021-01-01 00:00:00'
  );
  const [calculatedTestingDays, setCalculatedTestingDays] = useState(0);
  const [endDate, setEndDate] = useState(
    localStorage.getItem('endDate') || '2024-08-01 00:00:00'
  );
  const [monthsDifference, setMonthsDifference] = useState(0);
  const [forecastHour, setForecastHour] = useState(
    Number(localStorage.getItem('forecastHour')) || 7
  );
  const [forecastDay, setForecastDay] = useState(
    Number(localStorage.getItem('forecastDay')) || 1
  );
  const [thresholdAmount, setThresholdAmount] = useState(
    Number(localStorage.getItem('thresholdAmount')) || 34
  );
  const [thresholdType, setThresholdType] = useState(
    localStorage.getItem('thresholdType') || 'High'
  );
  const [target, setTarget] = useState('High');
  const [neededPrecision, setNeededPrecision] = useState(
    Number(localStorage.getItem('neededPrecision')) || 0.9
  );
  const [backupPrecision, setBackupPrecision] = useState(
    Number(localStorage.getItem('backupPrecision')) || 0.7
  );
  const [invocationType, setInvocationType] = useState(
    localStorage.getItem('invocationType') || 'backtesting'
  );
  const [resultData, setResultData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [parsedResultData, setParsedResultData] = useState(null);
  const [socketMessage, setSocketMessage] = useState('Function is running...');
  const jepxApiKey = process.env.REACT_APP_JEPX_API_KEY;
  //#endregion
  //State Variables

  //#region WebSocket Connection
  useEffect(() => {
    socketRef.current = new WebSocket(
      'wss://b4yzfhk6ec.execute-api.eu-west-1.amazonaws.com/production/'
    );

    socketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    socketRef.current.onmessage = (event) => {
      let event_data = JSON.parse(event.data);
      console.log('Received message:', event_data);

      if (event_data.type === 'RESULT') {
        setResultData(event_data.body);
        setShowModal(false);
        localStorage.removeItem('resultData');
        localStorage.setItem('resultData', event_data.body);
      } else if (event_data.type === 'LOADING') {
        setSocketMessage(event_data.body);
      } else if (event_data.type === 'ERROR') {
        setSocketMessage(event_data.body);
        alert('Error running the function. Please try again.');
        setShowModal(false);
      }
    };

    socketRef.current.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
      window.location.reload();
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        console.log('WebSocket disconnected');
      }
    };
  }, []);
  //#endregion
  //WebSocket Connection

  //#region Send Request
  const sendRequest = () => {
    //calculate actual start date
    setSocketMessage('Function is running...');
    var date = new Date(startDate);
    date.setMonth(date.getMonth() - testingDays);
    var actualStartDate = date.toISOString().slice(0, 10) + ' 00:00:00';

    if (date.getFullYear() < 2021) {
      alert(
        `The function's start date is ${actualStartDate} which cannot be earlier than 2021-01-01`
      );
    }

    var monthsDifference =
      new Date(endDate).getTime() - new Date(actualStartDate).getTime();
    monthsDifference = monthsDifference / (1000 * 3600 * 24 * 30);
    setMonthsDifference(monthsDifference);

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const requestObject = {
        action: action,
        start_date: actualStartDate,
        end_date: endDate,
        forecast_hour: forecastHour,
        forecast_day: forecastDay,
        testing_days: calculatedTestingDays,
        threshold_amount: thresholdAmount,
        threshold_type: thresholdType,
        target: target,
        needed_precision: neededPrecision,
        backup_precision: backupPrecision,
        invocation_type: invocationType,
      };

      const requestBody = JSON.stringify(requestObject);
      socketRef.current.send(requestBody);
      console.log('Sent message:', requestBody);

      if (monthsDifference > 24) {
        console.log(
          'Sending ping after 8 minutes. Months difference:' + monthsDifference
        );
        setTimeout(
          () => {
            if (
              socketRef.current &&
              socketRef.current.readyState === WebSocket.OPEN
            ) {
              const pingObject = {
                action: 'ping',
              };

              const pingBody = JSON.stringify(pingObject);
              socketRef.current.send(pingBody);
              console.log('Sent ping:', pingBody);
            }
          },
          8 * 60 * 1000
        ); // Send ping after 8 minutes because the result takes more than 6 minutes to load
      }
    }
  };
  //#endregion
  //Send Request

  //#region Retrieve stored result data from local storage
  const handleSendRequest = () => {
    var date = new Date(startDate);
    date.setMonth(date.getMonth() - testingDays);
    var actualStartDate = date.toISOString().slice(0, 10) + ' 00:00:00';

    if (date.getFullYear() < 2021) {
      alert(
        `The function's start date is ${actualStartDate} which cannot be earlier than 2021-01-01`
      );
    } else {
      console.log('Sending request...');
      sendRequest();
      setShowModal(true);
      localStorage.clear();
      localStorage.setItem('startDate', startDate);
      localStorage.setItem('endDate', endDate);
      localStorage.setItem('forecastHour', forecastHour);
      localStorage.setItem('forecastDay', forecastDay);
      localStorage.setItem('testingMonths', testingDays);
      localStorage.setItem('thresholdAmount', thresholdAmount);
      localStorage.setItem('thresholdType', thresholdType);
      localStorage.setItem('neededPrecision', neededPrecision);
      localStorage.setItem('backupPrecision', backupPrecision);
      localStorage.setItem('invocationType', invocationType);
    }
  };
  const storedResultData = localStorage.getItem('resultData');

  useEffect(() => {
    let parsedData = null;
    let tableVisible = false;

    try {
      // Parse the stored result data from JSON string to an object
      parsedData = JSON.parse(storedResultData);
      // Check if cutoff_df is available in parsedResultData
      if (parsedData && parsedData.cutoff_df) {
        tableVisible = true; // Set the flag to true to show the table
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }

    setParsedResultData(parsedData);
    setShowTable(tableVisible);
  }, [storedResultData]);

  const closeModal = () => {
    setShowModal(false);
  };
  //#endregion
  //Retrieve stored result data from local storage

  //#region Download CSV with file-saver package
  function downloadCSV() {
    if (tableRef.current) {
      let csv = [];
      let rows = tableRef.current.getElementsByTagName('tr');

      for (let i = 0; i < rows.length; i++) {
        let row = [],
          cols = rows[i].querySelectorAll('td, th');

        for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);

        csv.push(row.join(','));
      }

      csv.push(``);
      csv.push(`INPUTS`);
      csv.push(`start_date:,${startDate}`);
      csv.push(`end_date:,${endDate}`);
      csv.push(`forecast_hour:,${forecastHour}`);
      csv.push(`forecast_day:,${forecastDay}`);
      csv.push(`testing_days:,${calculatedTestingDays}`);
      csv.push(`training_months:,${testingDays}`);
      csv.push(`threshold_amount:,${thresholdAmount}`);
      csv.push(`threshold_type:,${thresholdType}`);
      csv.push(`target:,${target}`);
      csv.push(`needed_precision:,${neededPrecision}`);
      csv.push(`backup_precision:,${backupPrecision}`);
      csv.push(`invocation_type:,${invocationType}`);
      csv.push(``);
      csv.push(`Best Cutoff:,${JSON.stringify(parsedResultData.best_cutoff)}`);
      if (parsedResultData.share_prices) {
        csv.push(
          `Training Share Price:,${parsedResultData.share_prices.training_share_price.toFixed(
            3
          )}`
        );
        csv.push(
          `Testing Share Price:,${parsedResultData.share_prices.testing_share_price.toFixed(
            3
          )}`
        );
        csv.push(
          `Total Share Price:,${parsedResultData.share_prices.total_share_price.toFixed(
            3
          )}`
        );
      }

      // Download CSV file
      let csvData = new Blob([csv.join('\n')], { type: 'text/csv' });
      saveAs(csvData, `jepx-${invocationType}-${startDate}-${endDate}.csv`);
    } else {
      console.error('Table not available');
    }
  }
  //#endregion
  //Download CSV with file-saver package

  //#region Calculate Testing Days
  function calculateTestingDays() {
    var calculatedStartDate = new Date(startDate);
    var calculatedEndDate = new Date(endDate);
    var timeDifference =
      calculatedEndDate.getTime() - calculatedStartDate.getTime();
    var daysDifference = timeDifference / (1000 * 3600 * 24);
    setCalculatedTestingDays(daysDifference);

    var date = new Date(startDate);
    date.setMonth(date.getMonth() - testingDays);
    var actualStartDate = date.toISOString().slice(0, 10) + ' 00:00:00';
    setTrainingStartDate(actualStartDate);
  }

  useEffect(() => {
    calculateTestingDays();
  }, [startDate, endDate, testingDays]);
  //#endregion
  //Calculate Testing Days

  //#region Save to DB Modal and fetch treshold & cutoff history from DB
  const [showSaveToDBModal, setShowSaveToDBModal] = useState(false);
  const [jepxHistoryTreshold, setjepxHistoryTreshold] = useState([]);
  const [jepxHistoryCutoff, setjepxHistoryCutoff] = useState([]);

  function saveToDBModal() {
    setShowSaveToDBModal(true);
  }

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': '*/*', 'x-api-key': jepxApiKey },
    };

    fetch(
      'https://t2nnob4swf.execute-api.eu-west-1.amazonaws.com/dev/history',
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const jsonJepxHistoryCutoff = data.cutoff;
        const jsonJepxHistoryTreshold = data.threshold;
        setjepxHistoryCutoff(jsonJepxHistoryCutoff);
        setjepxHistoryTreshold(jsonJepxHistoryTreshold);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [showSaveToDBModal]);

  // Log the state values whenever they change
  useEffect(() => {}, [jepxHistoryCutoff, jepxHistoryTreshold]);
  //#endregion
  //Save to DB Modal and fetch history from DB and fetch treshold & cutoff history from DB

  //#region Save Cutoff and Treshold to DB
  const [thresholdLow, setThresholdLow] = useState('');
  const [thresholdHigh, setThresholdHigh] = useState('');
  const [cutoffLow, setCutoffLow] = useState('');
  const [cutoffHigh, setCutoffHigh] = useState('');
  const [saveToDbMessage, setSaveToDbMessage] = useState('');
  const [saveToDbMessageModal, setSaveToDbMessageModal] = useState(false);

  function saveCutoffandTresholdtoDb() {
    const data = {
      cutoff: {
        low_price_cutoff: cutoffLow,
        high_price_cutoff: cutoffHigh,
      },
      threshold: {
        low_price: thresholdLow,
        high_price: thresholdHigh,
      },
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': '*/*', 'x-api-key': jepxApiKey },
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    const requestOptionsGet = {
      method: 'GET',
      headers: { 'Content-Type': '*/*', 'x-api-key': jepxApiKey },
    };

    fetch(
      'https://t2nnob4swf.execute-api.eu-west-1.amazonaws.com/dev/save',
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const message = JSON.stringify(result).replace(/\\|"/g, '');
        setSaveToDbMessage(
          message.includes('successful') ? ` ${message} ✅` : ` ${message} ❌`
        );
        setSaveToDbMessageModal(true);
        // Fetch history after successfully saving cutoff and threshold to DB
        fetch(
          'https://t2nnob4swf.execute-api.eu-west-1.amazonaws.com/dev/history',
          requestOptionsGet
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            const jsonJepxHistoryCutoff = data.cutoff;
            const jsonJepxHistoryTreshold = data.threshold;
            setjepxHistoryCutoff(jsonJepxHistoryCutoff);
            setjepxHistoryTreshold(jsonJepxHistoryTreshold);
          })
          .then(() => {
            setThresholdLow('');
            setThresholdHigh('');
            setCutoffLow('');
            setCutoffHigh('');
          })
          .then(() => {
            // wait 1 second before closing the modal
            setTimeout(() => {
              setSaveToDbMessageModal(false);
            }, 2000);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
      .catch((error) => console.log('error', error));
  }

  //Low threshold ve cutoff 0 ile 1 arasında olacak

  //#endregion
  //Save Cutoff and Treshold to DB

  if (
    accounts &&
    !accounts[0].idTokenClaims.roles.includes(
      window.location.pathname.split('/')[1]
    )
  ) {
    return (
      <div>
        <p>You don't have permission to see this app. </p>
      </div>
    );
  }

  //#region Download Dataset

  function downloadDataset() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': '*/*', 'x-api-key': jepxApiKey },
      body: JSON.stringify({
        start_date: startDate,
        end_date: endDate,
        forecast_hour: forecastHour,
        forecast_day: forecastDay,
      }),
    };

    console.log(requestOptions);

    // Show loading message
    const loadingMessage = document.createElement('div');
    loadingMessage.id = 'loadingMessage';
    loadingMessage.className = 'jepx-dataset-download-loading-message'; // Add class name
    loadingMessage.innerText = 'Downloading... Please wait.';
    document.body.appendChild(loadingMessage);

    fetch(
      'https://imb8xm0dn9.execute-api.eu-west-1.amazonaws.com/default/JEPX-Backtesting-App-Docker-Dataset-Downloader/dataset',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const datasetUrl = JSON.parse(result).body.dataset;
        console.log(datasetUrl);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = datasetUrl;
        link.download = 'dataset.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Remove loading message
        document.body.removeChild(loadingMessage);
      })
      .catch((error) => {
        console.log('error', error);
        // Remove loading message in case of error
        document.body.removeChild(loadingMessage);
      });
  }

  return (
    <div className="jepx-main">
      {/* Show modal */}
      {showModal && (
        <div className="jepx-loading-modal">
          <div className="jepx-loading-modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <p>The Results are loading, you can play Mario while waiting :)</p>
            <p>{socketMessage}</p>
            <br />
            <div className="loading-bar">
              <div
                className="loading-bar-fill"
                style={{
                  animation: `loading ${monthsDifference * 16}s infinite`,
                }}
              ></div>
            </div>
            <br />
            <br />

            <iframe
              src="https://chromedino.com/mario/"
              scrolling="no"
              width="100%"
              height="65%"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      )}

      {/* Save to DB modal */}
      {showSaveToDBModal && (
        <div className="jepx-save-to-db-modal">
          <p className="close" onClick={() => setShowSaveToDBModal(false)}>
            X
          </p>

          <div className="jepx-save-to-db-modal-history-tables">
            <div className="jepx-save-to-db-modal-treshold-history-table">
              <h3>Threshold History</h3>
              <table>
                <thead>
                  <tr>
                    <th>Timeslot</th>
                    <th>Low Threshold</th>
                    <th>High Threshold</th>
                  </tr>
                </thead>
                <tbody>
                  {jepxHistoryTreshold &&
                    jepxHistoryTreshold.map((item, index) => (
                      <tr key={index}>
                        <td>{item[0][0]}</td>
                        <td>{item[0][1]}</td>
                        <td>{item[0][2]}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="jepx-save-to-db-modal-cutoff-history-table">
              <h3>Cutoff History</h3>
              <table>
                <thead>
                  <tr>
                    <th>Timeslot</th>
                    <th>Low Cutoff</th>
                    <th>High Cutoff</th>
                  </tr>
                </thead>
                <tbody>
                  {jepxHistoryCutoff &&
                    jepxHistoryCutoff.map((item, index) => (
                      <tr key={index}>
                        <td>{item[0][0]}</td>
                        <td>{item[0][1]}</td>
                        <td>{item[0][2]}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="jepx-save-to-db-modal-inputs">
            <div className="jepx-save-to-db-modal-inputs-treshold">
              <label htmlFor="thresholdLow">Low Threshold</label>
              <input
                type="number"
                id="thresholdLow"
                value={thresholdLow}
                onChange={(e) => setThresholdLow(e.target.value)}
              />
              <label htmlFor="thresholdHigh">High Threshold</label>
              <input
                type="number"
                id="thresholdHigh"
                value={thresholdHigh}
                onChange={(e) => setThresholdHigh(e.target.value)}
              />
            </div>
            <div className="jepx-save-to-db-modal-inputs-cutoff">
              <label htmlFor="cutoffLow">Low Cutoff</label>
              <input
                type="number"
                id="cutoffLow"
                value={cutoffLow}
                onChange={(e) => setCutoffLow(e.target.value)}
              />
              <label htmlFor="cutoffHigh">High Cutoff</label>
              <input
                type="number"
                id="cutoffHigh"
                value={cutoffHigh}
                onChange={(e) => setCutoffHigh(e.target.value)}
              />
            </div>
          </div>

          <button
            onClick={() => {
              // Your save logic here...
              saveCutoffandTresholdtoDb();
              // Clear the input fields
              setThresholdLow('');
              setThresholdHigh('');
              setCutoffLow('');
              setCutoffHigh('');
            }}
          >
            Save
          </button>
        </div>
      )}
      {saveToDbMessageModal && (
        <div className="jepx-save-to-db-message-modal">
          <p className="close" onClick={() => setSaveToDbMessageModal(false)}>
            X
          </p>
          <p>{saveToDbMessage}</p>
        </div>
      )}

      <div className="jepx-inputs">
        <div>
          <label htmlFor="invocationType">Invocation Type</label>
          <select
            id="invocationType"
            value={invocationType}
            onChange={(e) => setInvocationType(e.target.value)}
          >
            <option value="backtesting">Backtesting</option>
            <option value="evaluation">Evaluation</option>
          </select>
        </div>

        <hr className="jepx-hr" />

        <div className="jepx-inputs-dates">
          <div>
            <label htmlFor="startDate">Dataset Start Date</label>
            <input
              type="date"
              id="startDate"
              min="2020-01-01"
              max={new Date().toISOString().slice(0, 10)}
              value={startDate && startDate.slice(0, 10)}
              onChange={(e) => setStartDate(e.target.value + ' 00:00:00')}
            />
          </div>
          <div>
            <label htmlFor="endDate">Dataset End Date</label>
            <input
              type="date"
              id="endDate"
              min="2020-01-02"
              max={new Date().toISOString().slice(0, 10)}
              value={endDate && endDate.slice(0, 10)}
              onChange={(e) => setEndDate(e.target.value + ' 00:00:00')}
            />
          </div>
        </div>
        <div>
          <label htmlFor="testingDays">Training Period (Months)</label>

          <input
            id="testingDays"
            value={testingDays}
            onChange={(e) => setTestingDays(e.target.value)}
          ></input>
        </div>
        <hr className="jepx-hr" />
        <div className="jepx-testing-training-dates">
          <label>Testing Days: {calculatedTestingDays}</label>
          <label>Training Start Date: {trainingStartDate}</label>
          <label>Training End Date: {startDate}</label>
        </div>

        <hr className="jepx-hr" />
        <div className="jepx-inputs-dates">
          <div>
            <label htmlFor="forecastHour">Forecast Hour</label>
            <select
              id="forecastHour"
              value={forecastHour}
              onChange={(e) => setForecastHour(Number(e.target.value))}
            >
              <option value="7">7</option>
              <option value="14">14</option>
            </select>
          </div>
          <div>
            <label htmlFor="forecastDay">Forecast Day</label>
            <select
              id="forecastDay"
              value={forecastDay}
              onChange={(e) => setForecastDay(Number(e.target.value))}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>

        <hr className="jepx-hr" />
        <div className="jepx-inputs-dates">
          <div>
            <label htmlFor="thresholdAmount">Threshold Amount</label>
            <input
              type="number"
              id="thresholdAmount"
              value={thresholdAmount}
              onChange={(e) => setThresholdAmount(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="thresholdType">Threshold Type</label>
            <select
              id="thresholdType"
              value={thresholdType}
              onChange={(e) => setThresholdType(e.target.value)}
            >
              <option value="High">High</option>
              <option value="Low">Low</option>
            </select>
          </div>
        </div>
        <hr className="jepx-hr" />
        <div className="jepx-inputs-dates">
          <div>
            <label htmlFor="neededPrecision">Needed Precision</label>
            <input
              type="number"
              id="neededPrecision"
              value={neededPrecision}
              onChange={(e) => setNeededPrecision(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="backupPrecision">Backup Precision</label>
            <input
              type="number"
              id="backupPrecision"
              value={backupPrecision}
              onChange={(e) => setBackupPrecision(e.target.value)}
            />
          </div>
        </div>
        <button onClick={handleSendRequest}>Run</button>
        <button onClick={downloadDataset}>Download Dataset</button>
      </div>
      <div className="jepx-result">
        {showTable ? (
          <div className="jepx-result-main">
            <div className="jepx-result-table">
              <h3>Cutoff Table</h3>
              <table ref={tableRef}>
                <thead>
                  <tr>
                    <th>Cutoff</th>
                    <th>Precision</th>
                    <th>Recall</th>
                    <th>F1 Score</th>
                    <th>FN</th>
                    <th>FP</th>
                    <th>TN</th>
                    <th>TP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0.0</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.0'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.0'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.0'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.0']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.0']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.0']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.0']}</td>
                  </tr>
                  <tr>
                    <td>0.05</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.1']}</td>
                  </tr>
                  <tr>
                    <td>0.1</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.1'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.1']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.1']}</td>
                  </tr>
                  <tr>
                    <td>0.15</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.15'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.15'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.15'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.15']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.15']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.15']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.15']}</td>
                  </tr>
                  <tr>
                    <td>0.2</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.2'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.2'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.2'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.2']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.2']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.2']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.2']}</td>
                  </tr>
                  <tr>
                    <td>0.25</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.25'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.25'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.25'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.25']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.25']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.25']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.25']}</td>
                  </tr>
                  <tr>
                    <td>0.3</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.3'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.3'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.3'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.3']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.3']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.3']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.3']}</td>
                  </tr>
                  <tr>
                    <td>0.35</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.35'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.35'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.35'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.35']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.35']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.35']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.35']}</td>
                  </tr>
                  <tr>
                    <td>0.4</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.4'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.4'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.4'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.4']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.4']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.4']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.4']}</td>
                  </tr>
                  <tr>
                    <td>0.45</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.45'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.45'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.45'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.45']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.45']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.45']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.45']}</td>
                  </tr>
                  <tr>
                    <td>0.5</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.5'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.5'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.5'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.5']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.5']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.5']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.5']}</td>
                  </tr>
                  <tr>
                    <td>0.55</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.55'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.55'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.55'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.55']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.55']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.55']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.55']}</td>
                  </tr>
                  <tr>
                    <td>0.6</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.6'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.6'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.6'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.6']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.6']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.6']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.6']}</td>
                  </tr>
                  <tr>
                    <td>0.65</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.65'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.65'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.65'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.65']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.65']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.65']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.65']}</td>
                  </tr>
                  <tr>
                    <td>0.7</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.7'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.7'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.7'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.7']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.7']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.7']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.7']}</td>
                  </tr>
                  <tr>
                    <td>0.75</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.75'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.75'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.75'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.75']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.75']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.75']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.75']}</td>
                  </tr>
                  <tr>
                    <td>0.8</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.8'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.8'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.8'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.8']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.8']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.8']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.8']}</td>
                  </tr>
                  <tr>
                    <td>0.85</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.85'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.85'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.85'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.85']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.85']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.85']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.85']}</td>
                  </tr>
                  <tr>
                    <td>0.9</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.9'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.9'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.9'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.9']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.9']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.9']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.9']}</td>
                  </tr>
                  <tr>
                    <td>0.95</td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Precision[
                        '0.95'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).Recall[
                        '0.95'
                      ].toFixed(3)}
                    </td>
                    <td>
                      {JSON.parse(parsedResultData.cutoff_df).F1Score[
                        '0.95'
                      ].toFixed(3)}
                    </td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FN['0.95']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).FP['0.95']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TN['0.95']}</td>
                    <td>{JSON.parse(parsedResultData.cutoff_df).TP['0.95']}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Display other key-value pairs in <p> elements */}
            <div className="jepx-result-data">
              <div className="jepx-result-data-values">
                {parsedResultData.best_cutoff !== false ? (
                  <div className="jepx-result-data-best-cutoff">
                    <p>
                      Best Cutoff:{' '}
                      {JSON.stringify(parsedResultData.best_cutoff)}
                    </p>
                  </div>
                ) : (
                  <div className="jepx-result-data-best-cutoff">
                    <p>Best Cutoff hasn't been found</p>
                  </div>
                )}

                {parsedResultData.share_prices && (
                  <div className="jepx-result-data-share-price">
                    <p>
                      Training Share Price:{' '}
                      {parsedResultData.share_prices.training_share_price.toFixed(
                        3
                      )}
                    </p>
                    <p>
                      Testing Share Price:{' '}
                      {parsedResultData.share_prices.testing_share_price.toFixed(
                        3
                      )}
                    </p>
                    <p>
                      Total Share Price:{' '}
                      {parsedResultData.share_prices.total_share_price.toFixed(
                        3
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="jepx-result-data-csv-button">
                <button id="downloadCSVButton" onClick={downloadCSV}>
                  Download as CSV
                </button>
                <button id="saveCutoffButton" onClick={saveToDBModal}>
                  Save to DB
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p>Run the function to see results</p>
        )}
      </div>
    </div>
  );
};

export default Jepx;
