import React, { useEffect, useRef, useState } from 'react';
import { DataSet, Network } from 'vis-network/standalone';

const NetworkGraph = ({ nodes, edges, onNodeSelect }) => {
  const containerRef = useRef(null);
  const network = useRef(null);
  const colorList = [
    'red',
    'blue',
    'green',
    'yellow',
    'orange',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
  ];

  useEffect(() => {
    if (nodes && edges && containerRef.current) {
      if (network.current) {
        network.current.destroy();
      }
      // Create a DataSet with the nodes and edges
      const nodesDataSet = new DataSet();
      const edgesDataSet = new DataSet();

      nodesDataSet.clear();
      edgesDataSet.clear();

      nodesDataSet.add(
        nodes.map((node) => ({
          ...node,
          label: node.label ? node.label.replace(/[\s.]/g, '\n') : '',
          color: colorList[node.level % colorList.length],
          size: 15,
        }))
      );
      edgesDataSet.add(
        edges.map((edge) => ({
          ...edge,
          color: { color: 'rgba(0, 0, 0, 0.2)' }, // 20% transparent black
        }))
      );

      const options = {
        layout: {
          hierarchical: {
            direction: 'UD', // Change this to 'DU', 'LR', or 'RL' as needed
            sortMethod: 'directed', // This ensures the lower levels are below higher levels
          },
        },
      };

      // Create a Network with the container, nodes, and edges
      network.current = new Network(
        containerRef.current,
        { nodes: nodesDataSet, edges: edgesDataSet },
        options
      );

      // Add a selectNode event listener
      network.current.on('selectNode', function (params) {
        const nodeId = params.nodes[0]; // Get the id of the selected node
        const selectedNode = nodesDataSet.get(nodeId); // Get the selected node object

        console.log('Selected node label:', selectedNode.label); // Log the label of the selected node

        // Update the selected node label state in Home
        if (onNodeSelect) {
          onNodeSelect(selectedNode.label);
        }

        // Change the color of the selected node and its connected nodes and edges
        nodesDataSet.get().forEach((node) => {
          if (
            node.id === nodeId ||
            network.current.getConnectedNodes(nodeId).includes(node.id)
          ) {
            nodesDataSet.update({
              id: node.id,
              color: colorList[node.level % colorList.length],
            });
          } else {
            nodesDataSet.update({ id: node.id, color: 'grey' });
          }
        });
        edgesDataSet.get().forEach((edge) => {
          if (edge.from === nodeId || edge.to === nodeId) {
            edgesDataSet.update({
              id: edge.id,
            });
          } else {
            edgesDataSet.update({ id: edge.id });
          }
        });
      });

      // Add a deselectNode event listener
      network.current.on('deselectNode', function () {
        // Reset the color of all nodes and edges
        nodesDataSet.get().forEach((node) => {
          nodesDataSet.update({
            id: node.id,
            color: colorList[node.level % colorList.length],
          });
        });
        edgesDataSet.get().forEach((edge) => {
          edgesDataSet.update({
            id: edge.id,
          });
        });
      });
    }
  }, [nodes, edges]);

  return <div ref={containerRef} style={{ height: '80vh', width: '100%' }} />;
};

export default NetworkGraph;