import React, { useState, useEffect } from 'react';
import ReusableModal from '../../../components/Modal';
import ReusableSelect from '../../../components/Select';
import Wait from '../../../components/wait';

const UpdateObjectModal = ({
  isOpen,
  onClose,
  selectedObject,
  setSelectedObject,
  classes,
  objects,
  abstractClasses,
  selectedClass,
  updateObject,
  getObjects,
  setObjects,
}) => {
  const [updatedObjectData, setUpdatedObjectData] = useState(null);

  useEffect(() => {
    if (selectedObject) {
      setUpdatedObjectData({ ...selectedObject });
    }
  }, [selectedObject]);

  const handleUpdateObjectChange = (eventOrSelectedOption, propName, inputType) => {
    if (eventOrSelectedOption.target) {
      let newValue = eventOrSelectedOption.target.value;
      if (inputType === 'number') {
        newValue = Number(newValue);
      }

      setSelectedObject((prevState) => ({
        ...prevState,
        [propName]: newValue,
      }));
    } else {
      setSelectedObject((prevState) => ({
        ...prevState,
        [propName]: eventOrSelectedOption,
      }));
    }
  };

  const handleUpdate = () => {
    // Validate required fields
    const missingRequiredFields = selectedClass.required.filter((field) => !selectedObject[field]);

    if (missingRequiredFields.length > 0) {
      alert(`The following required fields are missing: ${missingRequiredFields.join(', ')}`);
      return; // Abort the function
    }

    updateObject(updatedObjectData, selectedObject._id.$oid)
      .then(() => Wait(1000))
      .then(() => getObjects().then((data) => setObjects(data)))
      .then(() => {
        setSelectedObject(null);
        setUpdatedObjectData(null);
        onClose();
      });
  };

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={() => {
        setSelectedObject(null);
        setUpdatedObjectData(null);
        onClose();
      }}
    >
      {selectedObject && (
        <div className="flex-db-object-details-modal">
          <div className="flex-db-object-details-modal-left">
            <h2>Update {selectedObject._name}</h2>
            {selectedClass &&
              Object.entries(selectedClass.properties)
                .filter(([propName, _]) => propName !== 'category' && propName !== 'subcategory')
                .map(([propName, propDetails], index) => {
                  const isRequired = selectedClass.required.includes(propName);
                  const inputType = propDetails.type === 'number' ? 'number' : 'text';

                  if (propDetails.type === 'array') {
                    const selectOptions = objects
                      .filter((objectItem) => {
                        const selectedTitle = selectedClass.properties[propName]?.items?.title;
                        const abstractClassTitles = abstractClasses.map((abstractClass) => abstractClass.title);
                        if (abstractClassTitles.includes(selectedTitle)) {
                          return objectItem.category === selectedTitle;
                        }
                        return (
                          objectItem.subcategory === selectedTitle ||
                          objectItem.subcategory === propDetails.items?.title
                        );
                      })
                      .map((objectItem) => ({
                        value: { $oid: objectItem._id.$oid },
                        label: objectItem._name,
                      }));

                    const adjustedDefaultValue = selectOptions.filter((option) => {
                      if (Array.isArray(selectedObject[propName])) {
                        return selectedObject[propName].some((selectedItem) => selectedItem.label === option.label);
                      } else {
                        return selectedObject[propName] ? selectedObject[propName].label === option.label : false;
                      }
                    });

                    return (
                      <div key={propName}>
                        <label>{propName}</label>
                        <ReusableSelect
                          options={selectOptions.sort((a, b) => {
                            if (a.label && b.label) {
                              return a.label.localeCompare(b.label);
                            }
                            return 0; // If either label is undefined, consider them equal
                          })}
                          defaultValue={adjustedDefaultValue}
                          onChange={(selectedOption) => {
                            handleUpdateObjectChange(selectedOption, propName);
                          }}
                          isMulti={true}
                          width={'31vw'}
                          placeholder={`Select ${propName}`}
                        />
                      </div>
                    );
                  } else if (propDetails.enum) {
                    const enumOptions = propDetails.enum.map((enumValue) => ({
                      value: enumValue,
                      label: enumValue,
                    }));

                    return (
                      <div key={propName}>
                        <label>{propName}</label>
                        <ReusableSelect
                          options={enumOptions}
                          defaultValue={enumOptions.find((option) => option.value === selectedObject[propName])}
                          onChange={(selectedOption) => {
                            handleUpdateObjectChange(selectedOption.value, propName);
                          }}
                          width={'31vw'}
                          placeholder={`Select ${propName}`}
                        />
                      </div>
                    );
                  } else if ((propName.includes('date') || propName.includes('Date')) && propDetails.type === 'string') {
                    // If the property name includes 'date' or 'Date', render a date picker
                    return (
                      <div key={index}>
                        <label>{`${propName}:`}</label>
                        <input
                          type="date" // Use a date picker for the 'Date' field
                          value={selectedObject[propName] || ''}
                          onChange={(e) => handleUpdateObjectChange(e, propName, 'date')}
                          required={isRequired}
                          placeholder={isRequired ? 'required' : ''}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={propName}>
                        <label htmlFor={propName}>
                          {propName} {isRequired && '*'}
                        </label>
                        <input
                          type={inputType}
                          id={propName}
                          value={selectedObject[propName] || ''}
                          onChange={(e) => handleUpdateObjectChange(e, propName, inputType)}
                          required={isRequired}
                          placeholder={isRequired ? 'required' : ''}
                        />
                      </div>
                    );
                  }
                })}
            <button onClick={handleUpdate}>Update Object</button>
          </div>
        </div>
      )}
    </ReusableModal>
  );
};

export default UpdateObjectModal;