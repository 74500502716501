import React, { useState, useEffect } from 'react';
import ReusableModal from '../../../components/Modal';
import ReusableSelect from '../../../components/Select';

const UpdateSubCategoryModal = ({
  isOpen,
  onClose,
  selectedClass,
  classes = [], // Default to an empty array if classes is not provided
  abstractClasses = [], // Default to an empty array if abstractClasses is not provided
  objects = [], // Default to an empty array if objects is not provided
  updateSubCategory,
  getSubCategories,
  setSelectedClass,
  setClasses,
  getObjects,
  setObjects,
}) => {
  const [updatedClassName, setUpdatedClassName] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedProperties, setUpdatedProperties] = useState({});
  const [requiredProps, setRequiredProps] = useState([]);

  useEffect(() => {
    if (selectedClass) {
      setUpdatedClassName(selectedClass.title);
      setUpdatedDescription(selectedClass.description);
      setUpdatedProperties(selectedClass.properties);
      setRequiredProps(selectedClass.required || []);
    }
  }, [selectedClass]);

  const handleChange = (propName, field, value) => {
    setUpdatedProperties((prevProps) => ({
      ...prevProps,
      [propName]: {
        ...prevProps[propName],
        [field]: value,
      },
    }));
  };

  const handleNameChange = (e, propName) => {
    const newName = e.target.value;
    setUpdatedProperties((prevProps) => {
      const updatedProps = { ...prevProps };
      if (newName !== propName) {
        const prop = updatedProps[propName];
        delete updatedProps[propName];
        updatedProps[newName] = prop;
      }
      return updatedProps;
    });
  };

  const addNewProp = () => {
    const newPropName = `newProp${Object.keys(updatedProperties).length}`;
    setUpdatedProperties((prevProps) => ({
      ...prevProps,
      [newPropName]: { type: 'string', description: '' },
    }));
  };

  const removeProp = (propName) => {
    setUpdatedProperties((prevProps) => {
      const newProps = { ...prevProps };
      delete newProps[propName];
      return newProps;
    });
    setRequiredProps((prevRequired) =>
      prevRequired.filter((name) => name !== propName)
    );
  };

  const toggleRequired = (propName) => {
    setRequiredProps((prevRequired) =>
      prevRequired.includes(propName)
        ? prevRequired.filter((name) => name !== propName)
        : [...prevRequired, propName]
    );
  };

  const handleUpdateSubCategory = () => {

    if (!updatedClassName) {
      alert('Please fill in the Sub Category Name');
      return;
    }
  
    // Check if updatedClassName already exists in the classes state, excluding the current subcategory
    const classExists = classes.some(cls => cls.title === updatedClassName && cls.title !== selectedClass.title);
    if (classExists) {
      console.log(selectedClass);
      alert('Sub Category name already exists. Please choose a different name.');
      return;
    }

    updateSubCategory(
      updatedClassName,
      updatedDescription,
      updatedProperties,
      selectedClass,
      classes,
      abstractClasses,
      requiredProps
    )
      .then(() => new Promise((resolve) => setTimeout(resolve, 1000))) // Wait for 1 second
      .then(() => getSubCategories())
      .then((subCategories) => {
        setClasses(subCategories);

        // Update selectedClass based on the new subcategories
        const updatedSelectedClass = subCategories.find(
          (subCategory) => subCategory.title === selectedClass.title
        );
        setSelectedClass(updatedSelectedClass);

        return getObjects();
      })
      .then((objects) => {
        setObjects(objects);
        onClose();
      })
      .catch(console.error);
  };

  const linkingOptions = [
    {
      label: 'Sub Categories',
      options: Array.isArray(classes)
        ? classes
            .map((cls) => ({ value: cls.title, label: cls.title }))
            .sort((a, b) => {
              if (a.label && b.label) {
                return a.label.localeCompare(b.label);
              }
              return 0; // If either label is undefined, consider them equal
            })
        : [], // If classes is not an array, return an empty array
    },
    {
      label: 'Categories',
      options: Array.isArray(abstractClasses)
        ? abstractClasses
            .map((absCls) => ({
              value: absCls.title,
              label: absCls.title,
            }))
            .sort((a, b) => {
              if (a.label && b.label) {
                return a.label.localeCompare(b.label);
              }
              return 0; // If either label is undefined, consider them equal
            })
        : [], // If abstractClasses is not an array, return an empty array
    },
  ];

  const unitOptions = Array.isArray(objects)
    ? objects
        .filter((object) => object.subcategory === 'Units')
        .map((object) => ({
          value: object._name,
          label: object._name,
        }))
    : []; // If objects is not an array, return an empty array

  const showEnumColumn = Object.values(updatedProperties).some(
    (prop) => prop.enum || prop.type === 'enum'
  );

  const moveUp = (propName) => {
    const keys = Object.keys(updatedProperties);
    const index = keys.indexOf(propName);
    if (index > 0) {
      const newKeys = [...keys];
      [newKeys[index - 1], newKeys[index]] = [
        newKeys[index],
        newKeys[index - 1],
      ];
      const newProperties = {};
      newKeys.forEach((key) => {
        newProperties[key] = updatedProperties[key];
      });
      setUpdatedProperties(newProperties);
    }
  };

  const moveDown = (propName) => {
    const keys = Object.keys(updatedProperties);
    const index = keys.indexOf(propName);
    if (index < keys.length - 1) {
      const newKeys = [...keys];
      [newKeys[index + 1], newKeys[index]] = [
        newKeys[index],
        newKeys[index + 1],
      ];
      const newProperties = {};
      newKeys.forEach((key) => {
        newProperties[key] = updatedProperties[key];
      });
      setUpdatedProperties(newProperties);
    }
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      {selectedClass && (
        <div className="flex-db-update-class-modal">
          <h2>Update {selectedClass.title} Sub Category</h2>
          <div className="flex-db-update-class-modal-name-description">
            <label>Sub Category Name</label>
            <input
              type="text"
              value={updatedClassName}
              onChange={(e) => setUpdatedClassName(e.target.value)}
            />

            <label> Sub Category Description</label>
            <textarea
              type="text"
              value={updatedDescription}
              onChange={(e) => setUpdatedDescription(e.target.value)}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Linked Object</th>
                <th>Description</th>
                <th>Required</th>
                {showEnumColumn && <th>Enum Values</th>}
                <th>Unit</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(updatedProperties).map(([propName, prop]) => {
                if (['category', 'subcategory', '_name'].includes(propName))
                  return null;
                return (
                  <tr key={propName}>
                    <td>
                      <input
                        type="text"
                        value={propName}
                        autoFocus={true}
                        onChange={(e) => handleNameChange(e, propName)}
                        className="flex-db-add-sub-category-modal-input"
                      />
                    </td>
                    <td>
                        <select
                        value={prop.enum ? 'enum' : prop.type}
                        onChange={(e) => {
                          const newType = e.target.value;
                      
                          // Update the property type
                          handleChange(propName, 'type', newType);
                      
                          // If the new type is 'enum', initialize the enum array
                          if (newType === 'enum') {
                            handleChange(propName, 'enum', []);
                          } else {
                            handleChange(propName, 'enum', undefined); // Remove enum if type is not 'enum'
                          }
                        }}
                        className="flex-db-add-sub-category-modal-input"
                      >
                        <option value="">Select Type</option>
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="array">Linked Object</option>
                        <option value="enum">Enum</option>
                      </select>
                    </td>

                    <td>
                      {prop.type === 'array' ? (
                        <ReusableSelect
                          options={linkingOptions}
                          defaultValue={
                            prop.items && prop.items.title
                              ? {
                                  value: prop.items.title,
                                  label: prop.items.title,
                                }
                              : null
                          }
                          onChange={(selectedOption) => {
                            handleChange(propName, 'items', {
                              title: selectedOption ? selectedOption.value : '',
                            });
                          }}
                          placeholder="Select Linked Object"
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        value={prop.description || ''}
                        onChange={(e) =>
                          handleChange(propName, 'description', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={requiredProps.includes(propName)}
                        onChange={() => toggleRequired(propName)}
                      />
                    </td>
                    {showEnumColumn && (
                      <td>
                        {prop.enum || prop.type === 'enum' ? (
                          <input
                            type="text"
                            value={prop.enum && prop.enum.join(', ')}
                            onChange={(e) =>
                              handleChange(
                                propName,
                                'enum',
                                e.target.value.split(',').map((item) => item.trim())
                              )
                            }
                            placeholder="Enum values (comma-separated)"
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                    )}
                    <td>
                      {prop.type === 'number' && (
                        <ReusableSelect
                          options={unitOptions}
                          defaultValue={
                            prop.unit
                              ? { value: prop.unit, label: prop.unit }
                              : null
                          }
                          value={
                            prop.unit
                              ? { value: prop.unit, label: prop.unit }
                              : null
                          }
                          onChange={(selectedOption) =>
                            handleChange(
                              propName,
                              'unit',
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          placeholder="Select Unit"
                        />
                      )}
                    </td>
                    <td>
                      <button
                        id="flex-db-update-sub-category-remove-button"
                        onClick={() => removeProp(propName)}
                        title="Remove Property"
                      >
                        🗑
                      </button>
                      <button
                        id="flex-db-update-sub-category-move-up-button"
                        onClick={() => moveUp(propName)}
                        title="Move Up"
                      >
                        ↑
                      </button>
                      <button
                        id="flex-db-update-sub-category-move-down-button"
                        onClick={() => moveDown(propName)}
                        title="Move Down"
                      >
                        ↓
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button onClick={addNewProp}>Add New Property</button>
          <button
            id="flex-db-update-class-modal-update-button"
            onClick={handleUpdateSubCategory}
          >
            Update Sub Category
          </button>
        </div>
      )}
    </ReusableModal>
  );
};

export default UpdateSubCategoryModal;