import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import AuthRoute from './components/Authroute'; // our custom route guard
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

import App from './App';
import Navbar from './components/Navbar';
import FlexDB from './apps/flexDB/Home';
import Mvtool from './apps/mv-tool/Home';
import Jepx from './apps/jepx/Home';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="/flexdb/*"
          element={
            <AuthRoute>
              <Route index element={<FlexDB />} />
            </AuthRoute>
          }
        />
        <Route
          path="/mvtool/*"
          element={
            <AuthRoute>
              <Route index element={<Mvtool />} />
            </AuthRoute>
          }
        />
        <Route
          path="/jepx/*"
          element={
            <AuthRoute>
              <Route index element={<Jepx />} />
            </AuthRoute>
          }
        />
      </Routes>
    </Router>
  </MsalProvider>
);
