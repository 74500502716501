import React, { useState } from 'react';
import logo from '../energy-pool-logo.png';
import './Navbar.css';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { useLocation } from 'react-router-dom';
import AboutModal from './AboutModal';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
function Navbar(props) {
  const location = useLocation();
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const openAboutModal = () => {
    setIsAboutModalOpen(true);
  };

  const closeAboutModal = () => {
    setIsAboutModalOpen(false);
  };

  let locationEnvrioment = `(${window.location.hostname.split('.')[0]})`;
  if (locationEnvrioment === '(app)') {
    locationEnvrioment = '';
  }
  // You can use the pathname to determine the current route
  let name;
  switch (location.pathname) {
    case '/flexdb':
      name = `FLEX DB ${locationEnvrioment}`;
      break;
    case '/mvtool':
      name = `M&V Tool ${locationEnvrioment}`;
      break;
    case '/jepx':
      name = `JEPX Backtesting ${locationEnvrioment}`;
      break;
    default:
      name = `Internal App Portal ${locationEnvrioment}`;
  }

  const isAuthenticated = useIsAuthenticated();

  return (
    <nav>
      <div
        onClick={(e) => {
          e.preventDefault();
          window.location = '/';
        }}
        className="logo"
      >
        <img src={logo} height="50px" alt=""></img>
      </div>
      <div className="app-name">
        <p>{name}</p>
      </div>
      <div className="links">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            openAboutModal();
          }}
          rel="noreferrer"
        >
          About{' '}
        </a>
        <b>|</b>
        <a
          href="https://forms.office.com/e/CyBSuJSpVs"
          target="_blank"
          rel="noreferrer"
        >
          {' '}Report a Bug{' '}
        </a>
        <b>|</b>
        <a
          href="https://forms.office.com/e/6X3rfrVTQm"
          target="_blank"
          rel="noreferrer"
        >
          {' '}Contact{' '}
        </a>
        <b>|</b>
        {isAuthenticated ? <SignOutButton /> : <SignInButton />}
      </div>
      <AboutModal isOpen={isAboutModalOpen} onClose={closeAboutModal} />
    </nav>
  );
}

export default Navbar;