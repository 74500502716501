import apis from '../apis.js';

const addPathView = (name, rootSubcategory, pathDetails, onClose) => {
  let bodyData = JSON.stringify({
    name,
    rootSubcategory,
    pathDetails,
  });

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodyData,
    redirect: 'follow',
  };

  console.log(pathDetails);

  return fetch(apis.path.add, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      alert(data.body);
      if (onClose) {
        onClose();
      }
      return data;
    })
    .catch((error) => {
      alert('Error:', error);
      throw error;
    });
};

export default addPathView;
