import apis from '../apis.js';

const addObject = async (selectedClass, inputValues) => {
  // Turn strings into numbers if the property type is 'number'
  for (let prop in inputValues) {
    if (
      selectedClass.properties[prop] &&
      selectedClass.properties[prop].type === 'array'
    ) {
      inputValues[prop] = inputValues[prop].map((item) => ({
        id: { $oid: item.value },
        label: item.label,
      }));
    } else if (
      selectedClass.properties[prop] &&
      selectedClass.properties[prop].type === 'int'
    ) {
      console.log('Converting to number');
      inputValues[prop] = Number(inputValues[prop]);
    }
  }

  console.log(inputValues);

  let bodyData = JSON.stringify({
    subcategory: selectedClass.title,
    category: selectedClass.properties.category.const,
    ...inputValues,
  });

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodyData,
    redirect: 'follow',
  };

  console.log(requestOptions.body);

  fetch(apis.add.object, requestOptions)
    .then((response) => response.json())
    .then((data) => alert(data.message))
    .catch((error) => alert('Error:', error));
};

export default addObject;
