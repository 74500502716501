import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { SignInButton } from './components/SignInButton';
import './App.css';

const ProfileContent = () => {
  const { accounts } = useMsal();
  const roles = accounts[0].idTokenClaims.roles;

  // Creating an array of app data objects based on the roles
  //REALLY IMPORTANT: WHEN CREATING AZURE ROLE, THE URL WILL BE THE SAME AS THE ROLE NAME SO MAKE SURE TO NAME THE ROLE THE SAME AS THE URL ON INDEX.JS
  const appData = roles.map((role, index) => {
    return {
      title: role,
      href: `/${role}`,
    };
  });

  return (
    <>
      <div className="main-logged-in">
        <br />
        <br />

        <div className="main-all-apps">
          <div className="main-all-apps-title">
            <h1 className="card-title">Welcome {accounts[0].name}</h1>
          </div>
          <div className="main-all-apps-boxes">
            {appData.map((app, index) => {
              let title;
              if (app.title === 'jepx') {
                title = 'Jepx BackTesting';
              } else if (app.title === 'mvtool') {
                title = 'M&V Tool';
              } else if (app.title === 'flexdb') {
                title = 'Flex DB';
              } else {
                title = app.title;
              }

              return (
                <div className="app-box" key={index}>
                  <a href={app.href} className="app-title">
                    {title}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div id="main-ms-login">
          <h2 className="card-title">
            Sign in into Internal App Portal via Microsoft{' '}
          </h2>
          <br />
          <SignInButton />
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return <MainContent />;
}
