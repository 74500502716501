import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // replace '#root' with the id of your app's root element

function ReusableModal({ isOpen, onClose, children }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          backdropFilter: 'blur(5px)',
          zIndex: 1000,
        },
        content: {
          position: 'absolute',
          top: '8vh',
          left: '10vw',
          width: '80vw',
          height: '80vh',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '10px',
          outline: 'none',
          padding: '15px',
        },
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '2px',
          right: '5px',
          background: 'transparent',
          border: 'none',
          fontSize: '30px',
          cursor: 'pointer',
        }}
      >
        &times;
      </button>
      {children}
    </Modal>
  );
}

export default ReusableModal;