import React, { useState } from 'react';
import useDebounce from '../../../components/useDebounce';
import ReusableModal from '../../../components/Modal';
import ReusableSelect from '../../../components/Select';

const AddSubCategory = ({
  isOpen,
  onClose,
  selectedClassForModal,
  classes,
  abstractClasses,
  objects,
  addSubCategory,
  getSubCategories,
  setClasses,
}) => {
  const [className, setClassName] = useState('');
  const [subCategoryDescription, setSubCategoryDescription] = useState('');
  const [properties, setProperties] = useState([]);
  const [requiredProps, setRequiredProps] = useState(['_name']);

  const debouncedSetClassName = useDebounce((value) => {
    setClassName(value);
  }, 10);

  const addProperty = () => {
    setProperties([
      ...properties,
      {
        name: '',
        type: '',
        linkedObject: '',
        description: '',
        enum: '',
        unit: '',
        required: '',
      },
    ]);
  };

  const removeProperty = (index) => {
    const newProperties = properties.filter((_, i) => i !== index);
    setProperties(newProperties);
    setRequiredProps(
      requiredProps.filter(
        (name) => name !== properties[index].name || name === '_name'
      )
    );
  };

  const updateProperty = (index, field, value) => {
    const newProperties = [...properties];
    newProperties[index][field] = value;
    setProperties(newProperties);
  };

  const toggleRequired = (name) => {
    if (name === '_name') return;
    if (requiredProps.includes(name)) {
      setRequiredProps(requiredProps.filter((prop) => prop !== name));
    } else {
      setRequiredProps([...requiredProps, name]);
    }
  };

  const handleAddSubCategory = () => {
    if (!className) {
      alert('Please Fill the Sub Category Name');
      return;
    }

      // Check if className already exists in the classes state
    const classExists = classes.some(cls => cls.title === className);
    if (classExists) {
      alert('Sub Category name already exists. Please choose a different name.');
      return;
    }

    for (let key in properties) {
      if (properties[key].name === '' || !properties[key].type) {
        alert('Please provide name or type for the properties');
        return;
      }
    }

    const formattedProperties = properties.reduce((acc, prop) => {
      if (prop.type === 'enum') {
        acc[prop.name] = {
          enum: prop.enum.split(',').map((item) => item.trim()),
        };
      } else {
        acc[prop.name] = {
          type: prop.type,
          description: prop.description,
        };
        if (prop.unit) {
          acc[prop.name].unit = prop.unit;
        }
        if (prop.type === 'array' && prop.linkedObject) {
          const selectedOption = [...classes, ...abstractClasses].find(
            (cls) => cls.title === prop.linkedObject
          );
          const dbValue = classes.some((cls) => cls.title === prop.linkedObject)
            ? 'Subcategories'
            : 'Categories';
          acc[prop.name].items = {
            $ref: prop.linkedObject,
            $id: { $oid: selectedOption?._id.$oid },
            $db: dbValue,
          };
        }
      }
      return acc;
    }, {});

    addSubCategory(
      className,
      subCategoryDescription,
      formattedProperties,
      requiredProps,
      selectedClassForModal
    )
      .then(() => getSubCategories().then((data) => setClasses(data)))
      .then(() => {
        setClassName('');
        setProperties([]);
        onClose();
      });
  };

  const showEnumColumn = properties.some((prop) => prop.type === 'enum');

  const linkingoptions = [
    {
      label: 'Sub Categories',
      options: Array.isArray(classes)
        ? classes
            .map((cls) => ({ value: cls.id, label: cls.title }))
            .sort((a, b) => {
              if (a.label && b.label) {
                return a.label.localeCompare(b.label);
              }
              return 0; // If either label is undefined, consider them equal
            })
        : [], // If classes is not an array, return an empty array
    },
    {
      label: 'Categories',
      options: abstractClasses
        .map((absCls) => ({
          value: absCls.id,
          label: absCls.title,
        }))
        .sort((a, b) => {
          if (a.label && b.label) {
            return a.label.localeCompare(b.label);
          }
          return 0; // If either label is undefined, consider them equal
        }),
    },
  ];

  const moveUp = (index) => {
    if (index <= 0) return; // Can't move up the first element
    const newProperties = [...properties];
    [newProperties[index - 1], newProperties[index]] = [newProperties[index], newProperties[index - 1]];
    setProperties(newProperties);
  };

  const moveDown = (index) => {
    if (index >= properties.length - 1) return; // Can't move down the last element
    const newProperties = [...properties];
    [newProperties[index + 1], newProperties[index]] = [newProperties[index], newProperties[index + 1]];
    setProperties(newProperties);
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex-db-add-class-modal">
        <h2>Add New {selectedClassForModal} Sub Category</h2>
        <div className="flex-db-add-new-class-modal-name-description">
        
        <label>Sub Category Name</label>
        <input
          placeholder="Enter Sub Category Name"
          type="text"
          value={className}
          onChange={(e) => debouncedSetClassName(e.target.value)}
        />
        
        
        <label>Sub Category Description</label>
        <textarea
          placeholder="Enter Sub Category Description"
          type="text"
          value={subCategoryDescription}
          onChange={(e) => setSubCategoryDescription(e.target.value)}
        />
        
      </div>

        <div className="flex-db-add-new-class-modal-properties">
          <table className="flex-db-add-new-class-modal-table">
            <thead>
              <tr>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Name
                </th>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Type
                </th>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Linked Object
                </th>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Description
                </th>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Required
                </th>
                {showEnumColumn && (
                  <th className="flex-db-add-sub-category-modal-table-header">
                    Enum Values
                  </th>
                )}
                <th className="flex-db-add-sub-category-modal-table-header">
                  Unit
                </th>
                <th className="flex-db-add-sub-category-modal-table-header">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="flex-db-add-sub-category-modal-table-cell">
                  Name
                </td>
                <td className="flex-db-add-sub-category-modal-table-cell">
                  String
                </td>
                <td></td>
                <td>Default Attribute</td>
                <td>
                  <input type="radio" checked readOnly />
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {properties.map((prop, index) => (
                <tr key={index}>
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    <input
                      type="text"
                      placeholder="Name"
                      value={prop.name}
                      onChange={(e) => updateProperty(index, 'name', e.target.value)}
                      className="flex-db-add-sub-category-modal-input"
                    />
                  </td>

                  <td className="flex-db-add-sub-category-modal-table-cell">
                  <select
                    value={prop.type}
                    onChange={(e) => {
                      const newType = e.target.value;

                      // Update the property type
                      updateProperty(index, 'type', newType);

                    }}
                    className="flex-db-add-sub-category-modal-input"
                  >
                    <option value="">Select Type</option>
                    <option value="string">String</option>
                    <option value="number">Number</option>
                    <option value="array">Linked Object</option>
                    <option value="enum">Enum</option>
                  </select>

                  </td>
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    {prop.type === 'array' ? (
                      <ReusableSelect
                        value={prop.linkedObject}
                        onChange={(e) =>
                          updateProperty(index, 'linkedObject', e.label)
                        }
                        options={linkingoptions}
                        placeholder="Select Linked Object"
                      />
                    ) : null}
                  </td>
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    <input
                      type="text"
                      placeholder="Description"
                      value={prop.description}
                      onChange={(e) =>
                        updateProperty(index, 'description', e.target.value)
                      }
                      className="flex-db-add-sub-category-modal-input"
                    />
                  </td>
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    <input
                      type="checkbox"
                      checked={requiredProps.includes(prop.name)}
                      onChange={() => toggleRequired(prop.name)}
                    />
                  </td>
                  {showEnumColumn && (
                    <td className="flex-db-add-sub-category-modal-table-cell">
                      {prop.type === 'enum' ? (
                        <input
                          type="text"
                          placeholder="Enum values (comma-separated)"
                          value={prop.enum}
                          onChange={(e) =>
                            updateProperty(index, 'enum', e.target.value)
                          }
                          className="flex-db-add-sub-category-modal-input"
                        />
                      ) : null}
                    </td>
                  )}
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    {prop.type === 'number' && (
                      <ReusableSelect
                        value={prop.unit}
                        onChange={(e) => updateProperty(index, 'unit', e.label)}
                        options={objects
                          .filter((object) => object.subcategory === 'Units')
                          .map((object) => ({
                            value: object.Symbol,
                            label: object._name,
                          }))}
                        placeholder="Select Unit"
                      />
                    )}
                  </td>
                  <td className="flex-db-add-sub-category-modal-table-cell">
                    <button
                      onClick={() => removeProperty(index)}
                      className="flex-db-add-sub-category-modal-prop-remove-button"
                      title='Remove Property'
                    >
                      🗑
                    </button>
                    <button 
                      id='flex-db-update-sub-category-move-up-button'
                      onClick={() => moveUp(index)}
                      title='Move Up'
                      >
                        ↑
                    </button>
                    <button 
                      id='flex-db-update-sub-category-move-down-button'
                      onClick={() => moveDown(index)}
                      title='Move Down'
                      >
                        ↓
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: '10px' }}>
            <button
              onClick={addProperty}
              className="flex-db-add-sub-category-modal-add-prop-button"
            >
              + Add Property
            </button>
            <button
              onClick={handleAddSubCategory}
              className="flex-db-add-sub-category-modal-submit-button"
            >
              Add Sub Category
            </button>
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AddSubCategory;