import React, { useState, useEffect } from 'react';
import ReusableModal from '../../../components/Modal';
import ReusableSelect from '../../../components/Select';
import addPathView from '../crud/add/addPathView';

const AddPathViewModal = ({
    isOpen,
    onClose,
    classes = [],
    fetchPathViews
}) => {
    const [pathName, setPathName] = useState('');
    const [rootSubCategory, setRootSubCategory] = useState('');
    const [checkedItems, setCheckedItems] = useState({}); // Store checked items
    const [pathDetails, setPathDetails] = useState({}); // State to store the path structure


    useEffect(() => {
        if (!isOpen) {
            setPathName('');
            setRootSubCategory('');
            setCheckedItems({});
            setPathDetails({}); // Reset path details

        }
    }, [isOpen]);

    const classOptions = Array.isArray(classes) ? classes.map((cls) => ({
        value: cls.title,
        label: cls.title,
    })) : [];

    // Get the selected class properties
    const getSubCategories = (className) => {
        const selectedClass = classes.find(cls => cls.title === className);
        return selectedClass ? Object.entries(selectedClass.properties)
            .filter(([_, value]) => value.type === 'array') // Filter for array types
            .map(([key]) => key) : []; // Return keys of the array properties
    };

    // Recursive component to render checkbox tree
    const CheckboxTree = ({ className, marginLeft }) => {
        const subCategories = getSubCategories(className);

        return (
            <div style={{ marginLeft }}>
                {subCategories.map(subCategory => (
                    <div key={subCategory}>
                        <label>
                            <input 
                                type="checkbox" 
                                checked={checkedItems[subCategory] || false} 
                                onChange={() => handleCheckboxChange(subCategory)} 
                            />
                            {subCategory}
                        </label>
                        {/* Check for nested categories */}
                        {checkedItems[subCategory] && (
                            <CheckboxTree className={subCategory} marginLeft="20px" />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const handleCheckboxChange = (key) => {
        const newCheckedItems = { ...checkedItems, [key]: !checkedItems[key] };
        setCheckedItems(newCheckedItems);

        // Build path details based on current checked items
        const newPathDetails = buildPathDetails(newCheckedItems);
        setPathDetails(newPathDetails);
    };

    const buildPathDetails = (checkedItems) => {
        // Create the hierarchical structure
        const children = Object.keys(checkedItems)
            .filter(key => checkedItems[key]) // Only include checked items
            .map(key => ({
                name: key,
                children: [
                    { name: "_name" }, // Add _name to the first level
                    ...buildSubCategories(key, checkedItems) // Recursively add children
                ]
            }));
    
        return {
            name: "_name", // Ensure _name is added to the root level
            children
        };
    };
    
    const buildSubCategories = (className, checkedItems) => {
        const subCategories = getSubCategories(className);
        
        return subCategories.map(subCategory => {
            if (checkedItems[subCategory]) {
                return {
                    name: subCategory,
                    children: [
                        { name: "_name" }, // Add _name object for each subcategory level
                        ...buildSubCategories(subCategory, checkedItems) // Recursively check for more subcategories
                    ]
                };
            }
            return null;
        }).filter(child => child); // Filter out null values
    };    

    const handleSave = () => {
      const subCategories = getSubCategories(rootSubCategory);
      const filteredPathDetails = {
        ...pathDetails,
        children: pathDetails.children.filter(child => subCategories.includes(child.name))
      };
      console.log(filteredPathDetails);

      const sanitizedPathName = pathName.replace(/\s+/g, ''); // Remove all spaces from pathName

      addPathView(sanitizedPathName, rootSubCategory, filteredPathDetails, onClose)
            .then(() => {
                fetchPathViews();
                console.log('Path view added and views refreshed');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <ReusableModal
            isOpen={isOpen}
            onClose={onClose}
            title="Add Path View"
        >
            <div className='flex-db-add-pathview-modal'>
                <h3>Add Path View</h3>
                <div className='flex-db-add-pathview-modal-name-input'>
                    <label>Name</label>
                    <input
                        type="text"
                        value={pathName}
                        onChange={(e) => setPathName(e.target.value)}
                    />
                </div>
                <div className='flex-db-add-pathview-modal-rootclass-select'>
                    <label>Root Class</label>
                    <ReusableSelect
                        options={classOptions}
                        value={rootSubCategory ? { value: rootSubCategory, label: rootSubCategory } : null}
                        onChange={(e) => setRootSubCategory(e.value)}
                    />
                </div>
                {rootSubCategory && (
                    <div className='flex-db-add-pathview-modal-checkbox-tree'>
                        <label>Subcategories</label>
                        <CheckboxTree className={rootSubCategory} marginLeft="0px" />
                    </div>
                )}
                <button onClick={() => handleSave() }>Save</button>

            </div>
        </ReusableModal>
    );
};

export default AddPathViewModal;