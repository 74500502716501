/**
 *
 */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import './styles/flexDB.css';
import { useMsal } from '@azure/msal-react';

import { ReactComponent as DotIcon } from '../../assets/icons/dot.svg';
import { ReactComponent as AddDotIcon } from '../../assets/icons/add-dot.svg';
import { ReactComponent as DeleteDotIcon } from '../../assets/icons/delete-dot.svg';
import { ReactComponent as FolderIcon } from '../../assets/icons/folder-file-project.svg';
import { ReactComponent as FolderOpenIcon } from '../../assets/icons/folder-open.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

// UI Components
import NetworkGraph from '../../components/NetworkGraph';
import FlexDBTable from './components/flexDBTable';

// CRUD Function Components
import getCategories from './crud/get/getCategories';
import getSubCategories from './crud/get/getSubCategories';
import getObjects from './crud/get/getObjects';
import getPathViews from './crud/get/getPathViews';

import addCategory from './crud/add/addCategory';
import addSubCategory from './crud/add/addSubCategory';
import addObject from './crud/add/addObject';

import deleteCategory from './crud/delete/deleteCategory';
import deleteSubCategory from './crud/delete/deleteSubCategory';
import deleteObject from './crud/delete/deleteObject';
import deletePathView from './crud/delete/deletePathView';

import updateObject from './crud/update/updateObject';
import updateSubCategory from './crud/update/updateSubCategory';

// Modal Components
import AddCategory from './modals/addCategoryModal';
import AddSubCategory from './modals/addSubcategoryModal';
import AddObjectModal from './modals/addObjectModal';
import UpdateObjectModal from './modals/updateObjectModal';
import UpdateSubcategoryModal from './modals/updateSubcategoryModal';
import AddPathViewModal from './modals/addPathViewModal';
import getRequestedPathViews from './crud/get/getRequestedPathViews';

/**
 * FlexDB - Flexible Asset Management
 *
 * This React app provides a comprehensive interface for managing a flexible database system.
 * It allows users to interact with categories, subcategories, and objects in a hierarchical structure.
 *
 * Key Features:
 * - Dynamic sidebar for navigating categories and subcategories
 * - CRUD operations for categories, subcategories, and objects
 * - Table view for displaying and managing objects
 * - Network graph visualization for selected data
 * - Role-based access control (Admin, Pro, and regular users)
 * - Bulk object upload functionality
 * - Modals for adding, updating, and deleting various entities
 *
 * Component Structure:
 * - Left sidebar: Displays categories and subcategories
 * - Right content area: Shows either a table view of objects or a network graph visualization
 * - Modal components: For adding/editing categories, subcategories, and objects
 *
 * State Management:
 * - Uses React hooks (useState, useEffect, useCallback, useMemo) for state management
 * - Manages states for categories, subcategories, objects, selected items, and UI controls
 *
 * Data Fetching:
 * - Utilizes custom CRUD functions to interact with the backend API
 * - Implements useCallback for memoized fetch functions
 *
 * User Permissions:
 * - Restricts certain actions based on user roles (Admin, Pro, regular)
 * - Uses environment variables to define user roles
 *
 * Visualization:
 * - Offers table view for detailed object information
 * - Provides network graph visualization for selected data (demo implementation)
 *
 */

function FlexDB() {
  // #region USER CHECKS
  const { accounts } = useMsal();
  const userName = accounts[0].username;
  const adminUsers = JSON.parse(process.env.REACT_APP_FLEX_DB_ADMIN_USERS);
  const proUsers = JSON.parse(process.env.REACT_APP_FLEX_DB_PRO_USERS).concat(
    adminUsers
  );
  const isAdmin = useMemo(
    () => adminUsers.includes(userName),
    [adminUsers, userName]
  );
  const isPro = useMemo(
    () => proUsers.includes(userName),
    [proUsers, userName]
  );

  // #endregion
  // USER CHECKS

  // #region STATES
  const [abstractClasses, setAbstractClasses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [objects, setObjects] = useState([]);
  const [isAbstractModalOpen, setIsAbstractModalOpen] = useState(false); // Add Category Modal
  const [addClassModal, setaddClassModal] = useState(false); // Add Sub Category Modal
  const [updateClassModal, setUpdateClassModal] = useState(false); // Update Sub Category Modal
  const [selectedClassForModal, setSelectedClassForModal] = useState(null); // Selected Class for Modal
  const [selectedAbstractClasses, setSelectedAbstractClasses] = useState([]); // Selected Abstract Classes on Left Sidebar
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // Sidebar Collapse State
  const [isExpanded, setIsExpanded] = useState(false); // Expanded Views State
  const [tableView, setTableView] = useState(true); // Table or Network Graph View
  const [selectedVisualization, setSelectedVisualization] = useState(null); // Selected Visualization
  const [nodes, setNodes] = useState([]); // Network Graph Nodes
  const [edges, setEdges] = useState([]); // Network Graph Edges
  const [addObjectModalOpen, setAddObjectModalOpen] = useState(false); // Add Object Modal
  const [selectedClass, setSelectedClass] = useState(null); // Selected Class
  const [csvInput, setCsvInput] = useState(''); // CSV Input for Bulk Object Upload
  const [selectedObject, setSelectedObject] = useState(null); // Selected Object for Update
  const [pathViews, setPathViews] = useState([]); // Path Views
  const [requestedPathView, setRequestedPathView] = useState(null); // Requested Path View
  const [addPathViewModalOpen, setAddPathViewModalOpen] = useState(false); // Add Path View Modal
  const [expandedPathView, setExpandedPathView] = useState(null);
  const [filteredObjects, setFilteredObjects] = useState([]);

  // #endregion
  // STATES

  // #region FETCH FUNCTIONS
  const fetchCategories = useCallback(() => {
    getCategories().then((data) => setAbstractClasses(data));
  }, []);

  const fetchSubCategories = useCallback(() => {
    getSubCategories().then((data) => setClasses(data));
  }, []);

  const fetchObjects = useCallback(() => {
    getObjects().then((data) => setObjects(data));
  }, []);

  const fetchPathViews = useCallback(() => {
    getPathViews().then((data) => setPathViews(data));
  }, []);



  // UseEffect hooks with memoized fetch functions
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchSubCategories();
  }, [fetchSubCategories]);

  useEffect(() => {
    fetchObjects();
  }, [fetchObjects]);

  useEffect(() => {
    fetchPathViews();
  }, [fetchPathViews]);

  useEffect(() => {
    setTableView(true);
  }, [selectedClass]);

  useEffect(() => {
    if (expandedPathView) {
      const filtered = objects.filter(obj => obj.subcategory === expandedPathView.rootSubcategory);
      setFilteredObjects(filtered);
    }
  }, [expandedPathView, objects]);

  // #endregion
  // FETCH FUNCTIONS

  // #region ADD BULK OBJECTS

  const handleCsvInput = (e) => {
    setCsvInput(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const addBulkObjects = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', '*/*');
    myHeaders.append('x-api-key', process.env.REACT_APP_FLEX_DB_API_KEY);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: csvInput,
      redirect: 'follow',
    };

    fetch(
      `https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/dev/bulk_objects?className=${selectedClass.title}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => alert(JSON.parse(result).response))
      .then(() => getObjects())
      .then(() => setAddObjectModalOpen(false))
      .catch((error) => console.error(error));
  };

  // #endregion
  // ADD BULK OBJECTS

// #region CREATE NETWORK GRAPH

const createRequestedPathViewGraph = (requestedPathView) => {
  const nodes = [];
  const edges = [];

  // Find the only string in the requestedPathView object
  const topNodeLabel = requestedPathView._name || 'Top Node';

  // Define a list of shapes
  const shapes = ['dot', 'square', 'diamond', 'triangle', 'star'];

  const createNodes = (pathDetails, parentId = null, level = 0, edgeLabel = '', shapeIndex = 0) => {
    if (Array.isArray(pathDetails)) {
      pathDetails.forEach((pathDetail, index) => {
        const nodeId = nodes.length;
        const label = pathDetail._name || pathDetail.name;
        const shape = shapes[shapeIndex]; // Use the shape based on the main edge
        nodes.push({ id: nodeId, label, level, shape });
        if (parentId !== null) {
          edges.push({ from: parentId, to: nodeId, label: edgeLabel.split(' ').join('\n'), font: { size: 10 } });
        }
        Object.keys(pathDetail).forEach((key) => {
          if (Array.isArray(pathDetail[key])) {
            createNodes(pathDetail[key], nodeId, level + 1, key, shapeIndex);
          }
        });
      });
    } else if (typeof pathDetails === 'object') {
      Object.keys(pathDetails).forEach((key) => {
        if (Array.isArray(pathDetails[key])) {
          createNodes(pathDetails[key], parentId, level, key, shapeIndex);
        }
      });
    }
  };

  // Start with the top-level node
  const topNodeId = nodes.length;
  nodes.push({ id: topNodeId, label: topNodeLabel, level: 0, shape: 'dot' });
  
  // Create child nodes for each top-level property
  const topLevelChildren = Object.entries(requestedPathView)
    .filter(([key, value]) => Array.isArray(value));
  
  topLevelChildren.forEach(([key, value], index) => {
    createNodes(value, topNodeId, 1, key, index % shapes.length);
  });

  setNodes(nodes);
  setEdges(edges);
};

// #endregion
// CREATE NETWORK GRAPH

  // #region USER PERMISSIONS
  if (
    accounts &&
    !accounts[0].idTokenClaims.roles.includes(
      window.location.pathname.split('/')[1]
    )
  ) {
    return (
      <div>
        <p>You don't have permission to see this app. </p>
      </div>
    );
  }
  // #endregion
  // USER PERMISSIONS

  // #region PATH VIEW HANDLERS

  const handlePathViewClick = (pathView) => {
    if (expandedPathView === pathView) {
      setExpandedPathView(null);
    } else {
      setExpandedPathView(pathView);
    }
  };

  // #endregion
  // PATH VIEW HANDLERS

  return (
    <div className="flex-db-main">
      <div className={`flex-db-left-sidebar ${
          isSidebarCollapsed ? 'collapsed' : ''
        } `}
      >
        <button
          id="flex-db-left-sidebar-collapse-button"
          title="Collapse Sidebar"
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        >
          {isSidebarCollapsed ? '>' : '<'}
        </button>

        {!isSidebarCollapsed && (
          <>
            <div className="flex-db-left-sidebar-classes">
              <br />
              {abstractClasses.map((abstractClass, index) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      if (
                        selectedAbstractClasses.includes(abstractClass.title)
                      ) {
                        setSelectedAbstractClasses(
                          selectedAbstractClasses.filter(
                            (name) => name !== abstractClass.title
                          )
                        );
                      } else {
                        setSelectedAbstractClasses([
                          ...selectedAbstractClasses,
                          abstractClass.title,
                        ]);
                      }
                    }}
                  >
                    {selectedAbstractClasses.includes(abstractClass.title) ? (
                      <FolderOpenIcon />
                    ) : (
                      <FolderIcon />
                    )}{' '}
                    {abstractClass.title}
                  </button>

                                    <div className="flex-db-selected-classes-objects">
                    {selectedAbstractClasses.includes(abstractClass.title) &&
                      Array.isArray(classes) &&
                      classes
                        .filter(
                          (classItem) =>
                            classItem.properties.category && // Check if category is defined
                            classItem.properties.category.const && // Check if const is defined
                            classItem.properties.category.const.includes(abstractClass.title)
                        )
                        .map((classItem, classIndex) => (
                          <button
                            key={classIndex}
                            onClick={() => {
                              setSelectedClass(classItem);
                              setTableView(true);
                            }}
                            style={
                              selectedClass === classItem
                                ? { backgroundColor: '#f0f0f0' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            <DotIcon /> {classItem.title}{' '}
                            {isAdmin && (
                              <span
                                title="Edit Sub Category"
                                className="flex-db-edit-class-button"
                                onClick={() => setUpdateClassModal(true)}
                              >
                                ...
                              </span>
                            )}
                          </button>
                        ))}
                    {isAdmin && selectedAbstractClasses.includes(abstractClass.title) && (
                      <>
                        <button
                          onClick={() => {
                            setaddClassModal(true);
                            setSelectedClassForModal(abstractClass.title);
                          }}
                        >
                          <AddDotIcon /> + Add {abstractClass.title} Sub Category
                        </button>
                        <button
                          onClick={() => {
                            deleteCategory(abstractClass._id.$oid).then(() =>
                              getCategories().then((data) => setAbstractClasses(data))
                            );
                          }}
                          title="Delete The Category and all its elements"
                        >
                          <DeleteDotIcon /> - Delete {abstractClass.title} Category
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
              {isAdmin && (
                <button onClick={() => setIsAbstractModalOpen(true)}>
                  <FolderIcon /> + Add Category
                </button>
              )}
              <br />
            </div>
            <div className="flex-db-left-sidebar-subcategories">
              <br />
              {Array.isArray(classes) && classes
                .filter(
                    (classItem) =>
                        classItem.properties === null ||
                        classItem.properties.category === undefined || // Check if category is defined
                        !classItem.properties.category.const ||
                        classItem.properties.category.const === '' ||
                        classItem.properties.category.const === null
                )
                .map((classItem, classIndex) => (
                  <button
                    key={classIndex}
                    onClick={() => setSelectedClass(classItem)}
                    style={
                      selectedClass === classItem
                        ? { backgroundColor: '#f0f0f0' }
                        : { backgroundColor: '#fff' }
                    }
                  >
                    <DotIcon /> {classItem.title}{' '}
                    {isAdmin && (
                      <span
                        title="Edit Sub Category"
                        className="flex-db-edit-class-button"
                        onClick={() => setUpdateClassModal(true)}
                      >
                        ...
                      </span>
                    )}
                  </button>
                ))}
              {isAdmin && (
                <button onClick={() => setaddClassModal(true)}>
                  <AddDotIcon /> + Add Sub Category
                </button>
              )}
            </div>
            <div
              className={`flex-db-left-sidebar-visualization ${
                isExpanded ? 'expanded' : ''
              }`}
            >
              <button onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <>
                    <FolderOpenIcon />
                    <span> Hide Views</span>
                  </>
                ) : (
                  <>
                    <FolderIcon />
                    <span> Show Views</span>
                  </>
                )}
              </button>
              <br />
              {isExpanded && Array.isArray(pathViews) && (
  <div>
    {pathViews.map((pathView, index) => (
      <div key={index}>
        <button onClick={() => {
          setSelectedVisualization(pathView);
          handlePathViewClick(pathView)}}>
          {expandedPathView === pathView ? (
            <FolderOpenIcon  />
          ) : (
            <FolderIcon  />
          )} 
          {pathView.name}
        </button>
        {expandedPathView === pathView && (
          <div style={{ marginLeft: '20px' }}>
            {filteredObjects.map((obj, objIndex) => (
              <button
                key={objIndex}
                onClick={() => {
                  setTableView(false);
                  setSelectedVisualization(pathView);
                  getRequestedPathViews(pathView.name, obj._id.$oid).then((data) => {
                    setRequestedPathView(data);
                    createRequestedPathViewGraph(data);
                  });
                }}
              >
                <DotIcon  /> {obj._name}
              </button>
            ))}
              <button onClick={() => {
              deletePathView(selectedVisualization._id.$oid)
                .then(() => {
                  fetchPathViews();
                })
            }}>
                        <DeleteDotIcon />  Delete path view
            </button>
          </div>
        )}
      </div>
    ))}
    <button onClick={() => setAddPathViewModalOpen(true)}>
      <AddDotIcon  /> + Add Path View
    </button>
  </div>
)}
            </div>
          </>
        )}
      </div>

      <div className={`flex-db-right-sidebar ${
          isSidebarCollapsed ? 'expanded' : ''
        }`}
      >
        <div className="flex-db-table-div">
          {(selectedClass || selectedVisualization) && (
            <>
              {/* MAIN TABLE OR NETWORK DIV */}
              {!tableView ? (
                <div className="flex-db-table-div-network">
                  <div className="flex-db-table-div-network-graph">
                    {selectedVisualization && (
                      <p>{selectedVisualization.name}</p>
                    )}

                    {nodes && edges && (
                      <NetworkGraph nodes={nodes} edges={edges} />
                    )}
                  </div>
                  <div className="flex-db-table-div-network-right-sidebar">
                    {selectedVisualization && (
                      <div>
                        <h3>Path Details</h3>
                        <p>Root Class: {selectedVisualization.rootSubcategory}</p>
                        <pre>
                          {JSON.stringify(
                            selectedVisualization.pathDetails,
                            null,
                            2
                          )}
                        </pre>
                        
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex-db-table-div-buttons">
                    <p>
                      {selectedClass && selectedClass.title}
                      <a title={selectedClass && selectedClass.description}
                        className='flex-db-table-div-buttons-info-icon'
                      >ⓘ</a>
                    </p>
                    
                    {isPro && selectedClass && (
                      <button
                        onClick={() => {
                          setAddObjectModalOpen(true);
                        }}
                      >
                        + Add New {selectedClass.title}
                      </button>
                    )}
                    {isAdmin &&
                      selectedClass &&
                      selectedClass.title !== 'Units' && (
                        <button
                          id="flex-db-delete-class-button"
                          onClick={() => {
                            console.log(selectedClass._id.$oid);
                            deleteSubCategory(
                              selectedClass.title,
                              selectedClass._id.$oid
                            ).then(() =>
                              getSubCategories()
                                .then((data) => setClasses(data))
                                .then(() => setSelectedClass(null))
                            );
                          }}
                          title={`Delete ${selectedClass.title} Sub Category`}
                        >
                          <TrashIcon width="1.5vw" height="1.5vh" />
                        </button>
                      )}
                  </div>
                  <div className="flex-db-table-div-table">
                    <FlexDBTable
                      objects={objects}
                      selectedClass={selectedClass}
                      setSelectedObject={setSelectedObject}
                      deleteObject={deleteObject}
                      getObjects={getObjects}
                      setObjects={setObjects}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* MODALS */}

      {/* Add Category Modal */}
      <AddCategory isOpen={isAbstractModalOpen}
        onClose={() => setIsAbstractModalOpen(false)}
        addCategory={addCategory}
        getCategories={getCategories}
        setAbstractClasses={setAbstractClasses}
      />

      {/* Add Sub Category Modal */}
      <AddSubCategory isOpen={addClassModal}
        onClose={() => setaddClassModal(false)}
        selectedClassForModal={selectedClassForModal}
        classes={classes}
        abstractClasses={abstractClasses}
        objects={objects}
        addSubCategory={addSubCategory}
        getSubCategories={getSubCategories}
        setClasses={setClasses}
      />

      {/* Update Sub Category Modal */}
      <UpdateSubcategoryModal isOpen={updateClassModal}
        onClose={() => setUpdateClassModal(false)}
        selectedClass={selectedClass}
        classes={classes}
        abstractClasses={abstractClasses}
        objects={objects}
        updateSubCategory={updateSubCategory}
        getSubCategories={getSubCategories}
        setSelectedClass={setSelectedClass}
        setClasses={setClasses}
        getObjects={getObjects}
        setObjects={setObjects}
      />

      {/* Add Object Modal */}
      <AddObjectModal isOpen={addObjectModalOpen}
        onClose={() => setAddObjectModalOpen(false)}
        selectedClass={selectedClass}
        objects={objects}
        abstractClasses={abstractClasses}
        addObject={addObject}
        getObjects={getObjects}
        setObjects={setObjects}
        handleCsvInput={handleCsvInput}
        addBulkObjects={addBulkObjects}
      />

      {/* Update Object modal */}
      <UpdateObjectModal isOpen={selectedObject !== null}
        onClose={() => {
          setSelectedObject(null);
        }}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
        classes={classes}
        objects={objects}
        abstractClasses={abstractClasses}
        selectedClass={selectedClass}
        updateObject={updateObject}
        getObjects={getObjects}
        setObjects={setObjects}
      />

      {/* Add Path View Modal */}
      <AddPathViewModal isOpen={addPathViewModalOpen}
        onClose={() => setAddPathViewModalOpen(false)}
        classes={classes}
        objects={objects}
        abstractClasses={abstractClasses}
        pathViews={pathViews}
        fetchPathViews={fetchPathViews}
      />
    </div>
  );
}

export default FlexDB;