import apis from '../apis.js';

const deletePathView = (pathId) => {
  return new Promise((resolve, reject) => {
    console.log(pathId);
    if (window.confirm('Are you sure you want to delete this path?')) {
      let bodyData = JSON.stringify({
        id: pathId,
      });

      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: bodyData,
        redirect: 'follow',
      };

      fetch(apis.path.delete, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          alert(data.body);
          resolve(data);
        })
        .catch((error) => {
          alert('Error:', error);
          reject(error);
        });
    } else {
      alert('Deletion cancelled.');
      resolve('Deletion cancelled.');
    }
  });
};

export default deletePathView;