import apis from '../apis.js';

const getObjects = async () => {
  try {
    const response = await fetch(apis.get.objects);
    const data = await response.json();
    console.log('GET objects:', data);
    return data;
  } catch (error) {
    console.log("Couldn't GET objects:", error);
    return [];
  }
};
export default getObjects;
