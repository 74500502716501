import apis from '../apis.js';

const getSubCategories = async () => {
  try {
    const response = await fetch(apis.get.subCategories);
    const data = await response.json();
    console.log('Classes:', data);
    return data;
  } catch (error) {
    console.log("Couldn't GET classes:", error);
  }
};

export default getSubCategories;
