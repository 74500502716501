import apis from '../apis.js';

export default async function updateObject(updatedObjectData, id) {
  const dataWithoutId = { ...updatedObjectData };

  // Delete the _id property from the copy
  delete dataWithoutId._id;

  // Convert the modified copy to a JSON string
  const raw = JSON.stringify(dataWithoutId);

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: raw,
  };

  console.log(requestOptions.body);

  try {
    const response = await fetch(apis.update.object + id, requestOptions);
    const data = await response.json();
    alert(JSON.stringify(data.body));
  } catch (error) {
    alert('Error:', error);
  }
}
