import React, { useState, useEffect } from 'react';
import ReusableModal from '../../../components/Modal';
import ReusableSelect from '../../../components/Select';

const AddObjectModal = ({
  isOpen,
  onClose,
  selectedClass,
  objects,
  abstractClasses,
  addObject,
  getObjects,
  setObjects,
  handleCsvInput,
  addBulkObjects,
}) => {
  const [inputValues, setInputValues] = useState({});
  const [linkedObjects, setLinkedObjects] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setInputValues({});
      setLinkedObjects([]);
    }
  }, [isOpen]);

  const handleSelectChange = (event, prop) => {
    let value = '';
    if (selectedClass.properties[prop].type === 'array') {
      value = event;
      setLinkedObjects((prevState) => [...prevState, value]);
      console.log(linkedObjects);
    } else {
      value = event.target.value;
    }
    setInputValues((prevState) => ({ ...prevState, [prop]: value }));
  };

  const handleInputChange = (e, prop) => {
    if (!selectedClass || !selectedClass.properties) {
      console.error(
        'selectedClass or selectedClass.properties is null or undefined'
      );
      return;
    }

    let value = e.target.value || '-';

    if (selectedClass.properties[prop].type === 'number') {
      console.log('Converting to number');
      value = Number(value);
    }

    setInputValues((prevState) => ({ ...prevState, [prop]: value }));
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleAddObject = () => {
    // Validate required fields
    const missingRequiredFields = selectedClass.required.filter(
      (field) => !inputValues[field]
    );

    if (missingRequiredFields.length > 0) {
      alert(
        `The following required fields are missing: ${missingRequiredFields.join(
          ', '
        )}`
      );
      return; // Abort the function
    }

    addObject(selectedClass, inputValues)
      .then(() => {
        setInputValues({});
        setLinkedObjects([]);
        onClose();
      })
      .then(() => wait(1000))
      .then(() => getObjects().then((data) => setObjects(data)));
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex-db-add-new-object-modal">
        <div className="flex-db-add-new-object-modal-bulk">
          <label>
            <h3>Add Bulk {selectedClass && selectedClass.title}</h3>
          </label>
          <input type="file" id="bulkCsvFile" onChange={handleCsvInput} />
          <button onClick={addBulkObjects}>Add Bulk Objects</button>
        </div>
        <div className="flex-db-add-new-object-modal-objectname">
          <h3>Add New {selectedClass && selectedClass.title}</h3>
          {selectedClass &&
            selectedClass.properties &&
            Object.keys(selectedClass.properties)
              .filter(
                (prop) =>
                  ![
                    '_id',
                    '$schema',
                    'title',
                    'description',
                    'type',
                    'category',
                    'subcategory',
                  ].includes(prop)
              )
              .map((prop, index) => {
                const propDetails = selectedClass.properties[prop];
                const propType = propDetails
                  ? propDetails.type || propDetails.const || propDetails['$oid']
                  : undefined;
                const displayName = prop === '_name' ? 'Name' : prop;

                if ((prop.includes('date') || prop.includes('Date')) && propDetails.type === 'string') {
                  // If the property name is 'Date', render a date picker
                  return (
                    <div key={index} className="flex-db-add-new-object-modal-inputs">
                      <label>{`${displayName}:`}</label>
                      <input
                        type="date" // Use a date picker for the 'Date' field
                        onChange={(e) => handleInputChange(e, prop)}
                        required={
                          selectedClass.required &&
                          selectedClass.required.includes(prop)
                        }
                        placeholder={
                          selectedClass.required &&
                          selectedClass.required.includes(prop)
                            ? 'Required ' + propType
                            : '' + propType
                        }
                      />
                    </div>
                  );
                } else if (propDetails && propDetails.enum) {
                  return (
                    <div
                      key={index}
                      className="flex-db-add-new-object-modal-inputs-enum"
                    >
                      <label>{`${prop}:`}</label>
                      <select
                        onChange={(e) => handleSelectChange(e, prop)}
                        required={selectedClass.required.includes(prop)}
                      >
                        <option value="" disabled selected>
                          Select Value
                        </option>
                        {propDetails.enum.map((enumValue, enumIndex) => (
                          <option key={`enum-${enumIndex}`} value={enumValue}>
                            {enumValue}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                } else if (propType === 'array' || propType === 'object') {
                  return (
                    <div
                      key={index}
                      className="flex-db-add-new-object-modal-inputs-object"
                    >
                      <label>{`${prop}:`}</label>
                      <ReusableSelect
                        options={objects
                          .filter((objectItem) => {
                            const selectedTitle =
                              selectedClass.properties[prop].items?.title;
                            const abstractClassTitles = abstractClasses.map(
                              (abstractClass) => abstractClass.title
                            );
                            if (abstractClassTitles.includes(selectedTitle)) {
                              return objectItem.category === selectedTitle;
                            }
                            return objectItem.subcategory === selectedTitle;
                          })
                          .map((objectItem) => ({
                            value: objectItem._id.$oid,
                            label: objectItem._name,
                          }))
                          .sort((a, b) => {
                            if (a.label && b.label) {
                              return a.label.localeCompare(b.label);
                            }
                            return 0; // If either label is undefined, consider them equal
                          })} // Sorting alphabetically by label
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, prop)
                        }
                        isMulti
                        width={'20vw'}
                        required={selectedClass.required.includes(prop)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="flex-db-add-new-object-modal-inputs">
                      <label>
                        {displayName}
                        {propDetails.unit && ` (${propDetails.unit})`}:
                      </label>
                      <input
                        type={propType === 'number' ? 'number' : 'text'}
                        onChange={(e) => handleInputChange(e, prop)}
                        required={
                          selectedClass.required &&
                          selectedClass.required.includes(prop)
                        }
                        placeholder={
                          selectedClass.required &&
                          selectedClass.required.includes(prop)
                            ? 'Required ' + propType
                            : '' + propType
                        }
                      />
                    </div>
                  );
                }
              })}


          <button onClick={handleAddObject}>Add Object</button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AddObjectModal;