import apis from '../apis.js';

const addSubCategory = async (
  className,
  subCategoryDescription,
  properties,
  required,
  selectedClassForModal
) => {
  let defaultProperties = {
    _name: {
      type: 'string',
      description: 'Name of the object',
    },
    category: {
      const: selectedClassForModal,
    },
    subcategory: {
      const: className,
    },
  };

  let finalProperties = { ...defaultProperties, ...properties };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      title: className,
      description: subCategoryDescription,
      type: 'object',
      properties: finalProperties,
      required: required,
    }),
    redirect: 'follow',
  };

  console.log('Request options:', requestOptions.body);

  const response = await fetch(apis.add.subCategory, requestOptions);

  const text = await response.text();
  alert(text);
};

export default addSubCategory;
