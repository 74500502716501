import apis from '../apis.js';

const addCategory = async (abstractClassName, abstractClassDescription) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        $schema: 'https://json-schema.org/draft/2020-12/schema',
        title: abstractClassName,
        description: abstractClassDescription,
        type: 'object',
        properties: {
          abstractClass: {
            const: abstractClassName,
          },
        },
      }),
    };

    console.log('Request options:', requestOptions.body);

    const response = await fetch(apis.add.category, requestOptions);

    const data = await response.json();
    alert(JSON.stringify(data.body));
  } catch (error) {
    console.error('Error:', error);
  }
};

export default addCategory;
