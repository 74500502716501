import React from 'react';
import Select from 'react-select';

function ReusableSelect({
  options,
  defaultValue,
  onChange,
  isMulti = false,
  height,
  width,
  placeholder,
}) {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      isMulti={isMulti}
      placeholder={placeholder}
      styles={{
        control: (provided) => ({
          ...provided,
          fontFamily: 'Synonym-Regular',
          fontSize: '0.8em',
          height: height,
          minHeight: height,
          width: width,
        }),
        menu: (provided) => ({
          ...provided,
          fontFamily: 'Synonym-Regular',
          fontSize: '0.8em',
          width: width,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontFamily: 'Synonym-Regular',
          fontSize: '1em',
        }),
      }}
    />
  );
}

export default ReusableSelect;
