import apis from '../apis.js';

export default async function updateSubCategory(
  updatedClassName,
  updatedDescription,
  updatedProperties,
  selectedClass,
  classes,
  abstractClasses,
  requiredProps
) {
  // Convert updatedProperties object to an array for easier manipulation
  const updatedPropertiesArray = Object.entries(updatedProperties).map(
    ([name, prop]) => ({ name, ...prop })
  );

  // Check for properties with no name
  const missingNameProps = updatedPropertiesArray.filter((prop) => !prop.name);
  if (missingNameProps.length > 0) {
    alert('Please fill in the name for all properties.');
    return Promise.reject('Validation failed: Missing property names.');
  }

  // Check for properties with no type, excluding 'category' and 'subcategory'
  const missingTypeProps = updatedPropertiesArray.filter(
    (prop) =>
      (typeof prop.type !== 'string' || prop.type.trim() === '') &&
      prop.name !== 'category' &&
      prop.name !== 'subcategory'
  );
  if (missingTypeProps.length > 0) {
    console.log('missingTypeProps', missingTypeProps);
    alert('Please fill in the type for all properties.');
    return Promise.reject('Validation failed: Missing property types.');
  }

  const finalUpdatedProperties = updatedPropertiesArray.map((prop) => ({
    ...prop,
    type: prop.type === 'enum' ? 'string' : prop.type,
    enum: typeof prop.enum === 'string' ? prop.enum.split(',') : prop.enum,
  }));

  const transformProperties = (
    properties,
    selectedClass,
    classes,
    updatedClassName,
    abstractClasses
  ) => {
    if (!selectedClass || !selectedClass.properties) {
      console.error('Selected class or properties are undefined.');
      return {};
    }
    const transformed = properties.reduce((acc, { name, ...rest }) => {
      acc[name] = { ...rest };
      let refId = null;
      let ref = null;

      if (rest.type === 'array') {
        // Find class item
        const classItem = classes.find(
          (item) => item.title === (rest.items.title || '')
        );

        // Find abstract class item
        const abstractClassItem = abstractClasses.find(
          (item) => item.title === rest.items.title
        );

        if (classItem) {
          refId = classItem._id?.$oid || null;
          ref = classItem.title || name;
          acc[name].items = {
            $ref: ref,
            $id: { $oid: refId },
            $db: 'Subcategories',
          };
        } else if (abstractClassItem) {
          refId = abstractClassItem._id?.$oid || null;
          ref = abstractClassItem.title || null;
          acc[name].items = {
            $ref: ref,
            $id: { $oid: refId },
            $db: 'Categories',
          };
        } else {
          acc[name].items = {
            $ref: null,
            $id: { $oid: null },
            $db: 'Unknown',
          };
        }
      }

      return acc;
    }, {});

    // Adding constants to transformed properties
    transformed.category = {
      const: selectedClass?.properties?.category?.const || null,
    };
    transformed.subcategory = { const: updatedClassName };

    return transformed;
  };

  const lastFinalUpdatedProperties = transformProperties(
    finalUpdatedProperties,
    selectedClass,
    classes,
    updatedClassName,
    abstractClasses
  );

  // Remove unnecessary properties
  for (const prop of Object.keys(lastFinalUpdatedProperties)) {
    delete lastFinalUpdatedProperties[prop].required;
  }

  for (const [prop] of Object.entries(lastFinalUpdatedProperties)) {
    if (prop.enum && prop.type === 'array') {
      delete prop.enum;
    }
  }

  // Filter the required fields to only include those present in the properties
  const finalRequiredFields = requiredProps.filter((field) =>
    Object.keys(lastFinalUpdatedProperties).includes(field)
  );

  const raw = JSON.stringify({
    title: updatedClassName,
    description: updatedDescription,
    type: 'object',
    properties: lastFinalUpdatedProperties,
    required: finalRequiredFields,
  });

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: raw,
  };

  console.log('new props are:', JSON.parse(requestOptions.body));

  try {
    const response = await fetch(
      apis.update.subCategory + selectedClass._id.$oid,
      requestOptions
    );

    const data = await response.json();

    alert(JSON.stringify(data.body));
    return data;
  } catch (error) {
    console.error('Error updating class:', error);
    return Promise.reject('Error updating class.');
  }
}