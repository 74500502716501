import apis from '../apis.js';

const getCategories = async () => {
  try {
    const response = await fetch(apis.get.categories);
    const data = await response.json();
    console.log('Abstract classes:', data);
    return data; // Return the data directly
  } catch (error) {
    console.log("Couldn't GET Abstract classes:", error);
    return []; // Return an empty array in case of an error
  }
};

export default getCategories;
