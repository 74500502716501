import React, { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button, Link } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

// Helper function to check if a string is a URL
const isUrl = (str) => {
  return typeof str === 'string' && str.toLowerCase().startsWith('http');
};

// Helper function to render cell content
const renderCellContent = (value) => {
  if (isUrl(value)) {
    return (
      <Link
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        className="text-blue-600 hover:text-blue-800 underline"
      >
        {value}
      </Link>
    );
  }
  return value;
};

const FlexDBTable = ({
  objects,
  selectedClass,
  setSelectedObject,
  deleteObject,
  getObjects,
  setObjects,
}) => {
  const memoizedObjects = useMemo(() => objects, [objects]);
  const memoizedSelectedClass = useMemo(() => selectedClass, [selectedClass]);

  const filteredObjects = useMemo(() => {
    if (
      !memoizedObjects ||
      memoizedObjects.length === 0 ||
      !memoizedSelectedClass
    )
      return [];
    return memoizedObjects.filter(
      (object) => object.subcategory === memoizedSelectedClass.title
    );
  }, [memoizedObjects, memoizedSelectedClass]);

  const columns = useMemo(() => {
    if (filteredObjects.length === 0) return [];

    const firstObject = { ...filteredObjects[0] };
    const columnDefinitions = Object.keys(firstObject).map((property) => ({
      accessorKey: property,
      header: property,
      minSize: 50,
      maxSize: 400,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
        sx: {
          '& .Mui-TableHeadCell-Content': { justifyContent: 'flex-start' },
        },
      },
      Cell: ({ cell }) => renderCellContent(cell.getValue()),
    }));

    const nonEmptyColumns = columnDefinitions.filter((col) => col.header);
    const emptyColumns = columnDefinitions.filter((col) => !col.header);
    return [...nonEmptyColumns, ...emptyColumns];
  }, [filteredObjects]);

  const data = useMemo(() => {
    if (filteredObjects.length === 0) return [];

    return filteredObjects.map((object) => {
      const data = {};
      const emptyData = {};
      for (const key in object) {
        let value;
        if (Array.isArray(object[key])) {
          value = object[key]
            .map((item) => {
              if (item.id && item.label) {
                return `${item.label}`;
              }
              return item.label || JSON.stringify(item);
            })
            .join(', ');
        } else if (typeof object[key] === 'object' && object[key] !== null) {
          if ('$numberDouble' in object[key]) {
            value = isNaN(object[key].$numberDouble)
              ? 'NaN'
              : object[key].$numberDouble;
          } else if ('label' in object[key]) {
            value = object[key].label;
          } else {
            value = JSON.stringify(object[key]);
          }
        } else {
          value = object[key];
        }

        const propDetails = memoizedSelectedClass.properties[key];
        if (propDetails && propDetails.unit) {
          const unitObject = memoizedObjects.find(
            (obj) => obj._name === propDetails.unit
          );
          if (unitObject && unitObject.Symbol) {
            value = `${value} ${unitObject.Symbol}`;
          }
        }

        if (value) {
          data[key] = value;
        } else {
          emptyData[key] = value;
        }
      }
      return { ...data, ...emptyData };
    });
  }, [filteredObjects, memoizedSelectedClass, memoizedObjects]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    initialState: {
      density: 'compact',
      columnVisibility: {
        _id: false,
        type: false,
        dateCreated: false,
        subcategory: false,
        category: false,
      },
      pagination: { pageIndex: 0, pageSize: 25 },
    },
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    enableColumnOrdering: true,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '68.5vh' } },
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <Button
        key="edit"
        onClick={() => {
          const foundObject = objects.find(
            (object) => object._id.$oid === JSON.parse(row.original._id).$oid
          );

          if (foundObject) {
            setSelectedObject(foundObject);
          } else {
            console.error('No matching object found');
          }
        }}
      >
        Edit
      </Button>,
      <Button
        key="delete"
        onClick={() => {
          deleteObject(JSON.parse(row.original._id).$oid)
            .then(() => getObjects().then((data) => setObjects(data)))
            .catch((error) => console.error('Error:', error));
        }}
      >
        Delete
      </Button>,
    ],
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(event, row.original);
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Download All Data As CSV
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Download Selected Rows
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default FlexDBTable;
