import apis from '../apis';

const getRequestedPathViews = async (tree_name, object_id) => {
  try {
    const response = await fetch(`${apis.path.get}/${tree_name}/${object_id}`);
    const data = await response.json();
    console.log('GET requested path views:', data);
    return data;
  } catch (error) {
    console.log("Couldn't GET requested path views:", error);
    return [];
  }
};

export default getRequestedPathViews;
