import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReusableModal from './Modal';
import mvtoolImage from '../assets/images/mv-tool-architecture.jpg';
import portalImage from '../assets/images/stack.jpg';

const AboutModal = ({ isOpen, onClose }) => {

    const [releaseVersion, setReleaseVersion] = useState(null);
  
    useEffect(() => {
      async function fetchLatestRelease() {
        try {
          const response = await fetch('https://api.github.com/repos/EPTUR/EP-Internal-App-Portal/releases/latest'
            , {
              headers: {
                Authorization: `token ${process.env.REACT_APP_GITHUB_API_KEY}`
              }
            }
          );
          const data = await response.json();
          setReleaseVersion(data.tag_name);  // `tag_name` holds the release version
        } catch (error) {
          console.error("Error fetching the latest release:", error);
        }
      }
  
      fetchLatestRelease();
    }, []);

  const location = useLocation();

  const aboutSection = [
    {
      title: 'Internal App Portal',
      content: `The need for an internal app portal within an organization arises from the increasing complexity of modern business operations. An internal app portal serves as a centralized gateway to various tools, applications, and services, providing several critical benefits:
                
                Efficient Access: Employees can quickly and easily access the software and resources they require for their roles, improving productivity and reducing downtime.
                Enhanced User Experience: A well-designed portal offers a consistent and user-friendly interface, reducing the learning curve for new applications and improving overall user satisfaction.
                Security and Compliance: By controlling access through the portal, organizations can enforce security policies, monitor user activity, and ensure compliance with data protection regulations.
                Cost Optimization: Consolidating applications and services within a portal can lead to cost savings through resource optimization, reduced licensing fees, and improved resource allocation.
                Data Integration: The portal can integrate data from various sources, providing a comprehensive view of information, which can support better decision-making.
                Scalability: As the organization grows and introduces new tools, the portal can easily adapt and expand to accommodate additional applications and users.
                 
                In essence, an internal app portal is a strategic solution that not only simplifies access to critical tools but also enhances security, efficiency, and overall operational effectiveness within an organization. It aligns with the evolving needs of businesses in an increasingly digital and interconnected world.
                

                Tech Stack
                `,
      
      image: portalImage,
      frontendversion: releaseVersion,
      backendversion: 'v0.16.9',
      path: '/'
    },
    {
      title: 'JEPX Backtesting',
      content: 'JEPX Backtesting is a web application that allows users to backtest their trading strategies using historical data from the Japan Electric Power Exchange.',
      frontendversion: releaseVersion,
      backendversion: 'v1.0.0',
      path: '/jepx'
    },
    {
      title: 'FlexDB',
      content: `A wiki of the assets operated by Energy Pool.

The goal is to store the knowledge of Energy Pool : from the technical caracteristics of the equipments that we work with to the flexibility market specs and plant flex ratios.


The tool will allow us to :

Improve our flexibility, strategy and commercial studies

Develop the integrity of EP technical data

Share our knowledge to newcommers or partners`,
      frontendversion: releaseVersion,
      backendversion: 'v1.0.0',
      path: '/flexdb'
    },
    {
      title: 'M&V Tool',
      content: `MV Tool is an automated tool that generates the R1 reports of the customer automatically with a simple UI.\n
                User should download the customer data from Everest and upload it as input to the MV Tool.\n
                Tool generates the monthly report according to this input data and customer/site configurations.\n
                Returns two options: PDF and DOCX.`,
      image: mvtoolImage,
      frontendversion: releaseVersion,
      backendversion: 'v1.0.0',
      path: '/mvtool'
    }
  ];

  const locationEnvrioment = `(${window.location.hostname.split('.')[0]})`;
  const currentPath = location.pathname;
  const currentAboutSection = aboutSection.find(section => section.path === currentPath) || aboutSection[0];

  const renderContentWithLineBreaks = (content) => {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex-db-about">
        <h2>About</h2>
        {currentAboutSection.title && (
          <>
            <h3>{currentAboutSection.title} {locationEnvrioment}</h3>
            <p>{renderContentWithLineBreaks(currentAboutSection.content)}</p>
            {currentAboutSection.image && (
             <img loading='lazy' height={'400px'} src={currentAboutSection.image} alt={currentAboutSection.title} />
            )}
            <p>Frontend Version: {currentAboutSection.frontendversion}</p>
            <p>Backend Version: {currentAboutSection.backendversion}</p>
          </>
        )}
      </div>
    </ReusableModal>
  );
};

export default AboutModal;