import apis from '../apis.js';

const deleteCategory = async (id) => {
  if (window.confirm('Are you sure you want to delete this category?')) {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id }),
      };

      const response = await fetch(apis.delete.category, requestOptions);

      const data = await response.json();

      alert(JSON.stringify(data.body));
    } catch (error) {
      console.error('Error:', error);
    }
  } else {
    console.log('Delete Category cancelled');
  }
};

export default deleteCategory;
